import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import { IUserFromDB } from '../../../../interfaces/IUser';
import { mapUserFromDBToFe } from './helper/mapUserFromDbToFe';

export const useWhoAmIQuery = () => {
    const { postGql } = useApi();

    return useQuery({
        queryKey: ['whoAmI'],
        queryFn: async () => {
            const { whoAmI } = await postGql<{ whoAmI: IUserFromDB }>(
                'whoAmI',
                gql`
                    query {
                        whoAmI {
                            id
                            name
                            email
                            archived
                            fields {
                                name
                                value
                            }
                            roles {
                                id
                                name
                                tenantID
                                admin
                                access
                            }
                            tenantIDs
                            divisionID
                            ariba
                        }
                    }
                `
            );

            return mapUserFromDBToFe(whoAmI);
        },
    });
};
