import React, { PropsWithChildren } from 'react';
import { useUserSelf } from '../hooks/user/useUserSelf';

export interface IAuthGuard extends PropsWithChildren {}

export const AuthGuard: React.FC<IAuthGuard> = (props) => {
    const { children } = props;

    const { user } = useUserSelf();

    return <>{user && children}</>;
};
