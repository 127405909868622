import React, { useMemo } from 'react';
import { IContentLayoutItem, IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSTextItem } from '../elements/CMSTextItem';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';
import _ from 'lodash';

export interface ICMSLayoutTextText1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutTextText1: React.FC<ICMSLayoutTextText1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 2);

    const itemLeft = useMemo(() => {
        return { ...(layout.items[0] as IContentLayoutItem) };
    }, [layout.items]);

    /**
     * Copy the left item to the right if the right item is empty for Style and Layout Shift
     */
    const itemRight = useMemo(() => {
        const rightItem: IContentLayoutItem = _.cloneDeep({
            ...(layout.items[1] as IContentLayoutItem),
        });

        return { ...rightItem };
    }, [layout.items]);

    /**
     * If the headers are the same, we want to make them transparent
     */
    const transparentHeaders = useMemo((): boolean | undefined => {
        const leftItem = { ...(layout.items[0] as IContentLayoutItem) };
        const rightItem = { ...(layout.items[1] as IContentLayoutItem) };

        if (leftItem.title?.baseTranslation === rightItem.title?.baseTranslation) {
            return true;
        }
    }, [layout.items]);

    return (
        <div className="relative grid w-full grid-cols-2 gap-x-12">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            <div>
                {itemLeft && (
                    <CMSTextItem index={index} itemIndex={0} richTextClasses="CMSLayoutTextText1" />
                )}
            </div>
            <div>
                {itemRight && (
                    <CMSTextItem
                        transparentHeaders={transparentHeaders}
                        index={index}
                        itemIndex={1}
                        richTextClasses="CMSLayoutTextText1"
                    />
                )}
            </div>
        </div>
    );
};
