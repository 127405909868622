import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IContentPage } from '../../../../interfaces/CMS/cms.interfaces';
import { cmsSlugSelector } from '../../../frontend/areas/CMSPage/state/CMSPage.state';
import { useRecoilValue } from 'recoil';

export const useContentLayoutDeleteMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    const slug = useRecoilValue(cmsSlugSelector);

    return useMutation<IContentPage, unknown, { layoutID: string }>({
        mutationFn: async (input) => {
            const { contentLayoutDelete: data } = await postGql<{
                contentLayoutDelete: IContentPage;
            }>(
                'contentLayoutDelete',
                gql`
                    mutation ($layoutID: ID!) {
                        contentLayoutDelete(layoutID: $layoutID) {
                            pageID
                            layouts {
                                type
                                layoutID
                                items {
                                    itemID
                                    type
                                    title {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    subLine {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    content {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    media {
                                        height
                                        width
                                        id
                                        path
                                        tag
                                    }
                                    cta {
                                        href
                                        label {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        target
                                    }
                                    productIDs
                                    categoryIDs
                                    randomize
                                }
                            }
                        }
                    }
                `,
                { layoutID: input.layoutID }
            );

            return data;
        },
        onSuccess: () => {
            const key = [`contentPage-${slug ?? '-'}`];
            void queryClient.invalidateQueries({ queryKey: key });
        },
    });
};
