import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IContentPage } from '../../../../interfaces/CMS/cms.interfaces';
import { ITranslationInput } from '../../Translations/types/translation.interfaces';

export interface CLIUInput {
    itemID: string;
    type: string;
    title?: string;
    content?: string;
    subLine?: string;
    href?: string;
    label?: string;
    target?: string;
    categoryIDs?: string[];
    randomize?: boolean;
    mode?: string;
    titleTranslation?: ITranslationInput;
    contentTranslation?: ITranslationInput;
    sublineTranslation?: ITranslationInput; // Note: lowercase 'l' to match GraphQL API
    ctaLabelTranslation?: ITranslationInput;
}

export const useContentLayoutItemUpdateMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation<IContentPage, unknown, CLIUInput>({
        mutationFn: async (input) => {
            const { contentLayoutItemUpdate: data } = await postGql<{
                contentLayoutItemUpdate: IContentPage;
            }>(
                'contentLayoutItemUpdate',
                gql`
                    mutation ($input: CLIUInput!) {
                        contentLayoutItemUpdate(input: $input) {
                            pageID
                            slug
                            layouts {
                                layoutID
                                type
                                items {
                                    itemID
                                    type
                                    title {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    subLine {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    content {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    media {
                                        height
                                        width
                                        id
                                        path
                                        tag
                                    }
                                    cta {
                                        href
                                        label {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        target
                                    }
                                    productIDs
                                    categoryIDs
                                    randomize
                                }
                            }
                        }
                    }
                `,
                { input }
            );

            return data;
        },
        onSuccess: (contentPage: IContentPage) => {
            const key = [`contentPage-${contentPage?.slug ?? '-'}`];
            void queryClient.invalidateQueries({ queryKey: key });
        },
    });
};
