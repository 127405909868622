import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';

export const useCMSLayout = () => {
    const pageLayout = useRecoilValue(adminPageLayoutSelector);

    const getLayoutByIndex = useCallback(
        (index: number, itemIndex: number) => {
            // Get IContentItem from pageLayout
            return pageLayout?.[index]?.items[itemIndex];
        },
        [pageLayout]
    );

    return { getLayoutByIndex };
};
