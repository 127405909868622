export enum ECMSCTAMode {
    button = 'button',
    both = 'both',
    blank = 'blank',
}

export enum ECMSCTAModeLabels {
    button = 'Nur Button',
    both = 'Button und Link',
    blank = 'Nur Kachel',
}
