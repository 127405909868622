import { TRichTextDataObject } from './useRichTextDataTypeAnalyzer';

/**
 * Determines if a baseTranslation exists and contains actual content
 * @param baseTranslation The baseTranslation string to check
 * @returns boolean indicating whether the baseTranslation contains valid content
 */
export const baseTranslationExists = (baseTranslation: string): boolean => {
    // Check if baseTranslation exists
    if (!baseTranslation) {
        return false;
    }

    // Quick check for common empty values
    if (
        baseTranslation === '{}' ||
        baseTranslation === '' ||
        baseTranslation === '""'
    ) {
        return false;
    }

    try {
        // Check if it's a slatejs object
        if (baseTranslation.includes('"type":"slatejs"')) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const slateObj: TRichTextDataObject = JSON.parse(baseTranslation);

            // Check if it has non-empty content
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (slateObj.type === 'slatejs' && Array.isArray(slateObj.content)) {
                // Check if it's not just an empty paragraph
                return !(
                    slateObj.content.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.type === 'paragraph' &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.children?.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.children[0]?.text === ''
                );
            }
        } else {
            // Check for empty object
            if (baseTranslation === '{}') {
                return false;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
            const parsedContent = JSON.parse(baseTranslation);
            if (
                typeof parsedContent === 'object' &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                Object.keys(parsedContent).length === 0
            ) {
                return false;
            }

            // Handle direct slate content - reuse the already parsed content
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const contentObj = parsedContent;

            // Check if it's not just an empty paragraph
            return !(
                Array.isArray(contentObj) &&
                contentObj.length === 1 &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                contentObj[0]?.type === 'paragraph' &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                contentObj[0]?.children?.length === 1 &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                contentObj[0]?.children[0]?.text === ''
            );
        }
    } catch (e) {
        // If can't parse JSON, consider it not empty
        return (
            baseTranslation.length > 0 &&
            baseTranslation !== '{}' &&
            baseTranslation !== ''
        );
    }

    return true;
};
