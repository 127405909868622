import { useCallback } from 'react';
import _ from 'lodash';
import { IContentLayout, IContentLayoutItem } from '../../../../../interfaces/CMS/cms.interfaces';
import { useSetRecoilState } from 'recoil';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';
import { ITranslations } from '../../../../../interfaces/translation/translations.interfaces';
import { ECMSCTAMode } from '../enums/ECMSCTAMode.enum';

export type TCMSCTA = {
    label: ITranslations;
    href: string;
    target?: string;
    mode?: ECMSCTAMode;
};

export const useCTASaveToLayoutState = () => {
    const setPageLayout = useSetRecoilState(adminPageLayoutSelector);

    const saveToLayoutState = useCallback(
        (formData: TCMSCTA, index: number, basicItemIndex: number) => {
            // Update layout with id in pageLayout, update the items with index and JSON.stringify(value) in default
            setPageLayout((currVal) => {
                if (!currVal) return null;

                const newLayouts: IContentLayout[] = _.cloneDeep(currVal);

                // Loop all newLayouts and find layout.id === layout.id
                const newLayout = newLayouts[index] ?? undefined;

                // Update
                if (newLayout) {
                    const newItems = [...newLayout.items];
                    const currentItem = newItems[basicItemIndex] as IContentLayoutItem;

                    newItems[basicItemIndex] = {
                        ...currentItem,
                        cta: {
                            ...currentItem?.cta,
                            label: formData.label,
                            href: formData.href,
                            target: formData.target,
                            mode: formData.mode,
                        },
                    };

                    newLayout.items = newItems;
                    // Merge back newLayout into Layouts in the correct index by id
                    newLayouts[index] = newLayout;
                }

                return newLayouts;
            });
        },
        [setPageLayout]
    );

    return { saveToLayoutState };
};
