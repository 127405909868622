import React from 'react';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSImage } from '../../../elements/CMSMedia/CMSImage';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

interface ICMSImageRow6 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutImageRow6: React.FC<ICMSImageRow6> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 6);

    return (
        <div className="relative grid w-full grid-cols-2 md:grid-cols-[4fr,4fr,4fr] gap-3 md:gap-8">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            {[0, 1, 2, 3, 4, 5].map((iterator) => (
                <div className="relative flex" key={`image-${iterator}`}>
                    <CMSImage
                        layout={layout}
                        itemIndex={iterator}
                        imageHint={'Format: 1:1'}
                        maxImages={1}
                        index={index}
                        displayComponent="1by1"
                        showCTASettings={true}
                        showText={true}
                        requiredRatio={{
                            width: 1,
                            height: 1,
                        }}
                    />
                </div>
            ))}
        </div>
    );
};
