import React, { useMemo } from 'react';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { cmsPageDataSelector } from '../state/CMSPage.state';
import { useRecoilValue } from 'recoil';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { ICMSImageElement } from '../../../elements/CMSMedia/interfaces/CMSImageElement.infertaces';
import { CMSTextItem, ETextItemStyles } from './CMSTextItem';
import Link from 'next/link';
import { useAdminControlsState } from '../../../../general/elements/adminControls/state/adminControls.state';
import { ECMSCTAMode } from '../enums/ECMSCTAMode.enum';
import Image from 'next/image';
import { imagePathSanitizer } from '../../../../../helper/imagePathSanitizer';
import { useWindowDimensions } from '../../../../general/hooks/helper/useWindowDimensions';

export interface ICMSImage1By1 extends ICMSImageElement {}

export const CMSImage1by1: React.FC<ICMSImage1By1> = (props) => {
    const { item, layout, itemIndex, showEditor, showTextEditor = false, showText = false } = props;

    const contentPage = useRecoilValue(cmsPageDataSelector);

    const { isSmartphone } = useWindowDimensions();

    const { cmsControls } = useAdminControlsState();

    const index = useMemo(() => {
        // Get index from contentPage
        return contentPage?.layouts.findIndex(
            (layoutLocal) => layoutLocal.layoutID === layout.layoutID
        );
    }, [contentPage?.layouts, layout.layoutID]);

    const media: IMedia | undefined = useMemo(() => {
        return item?.media?.[0] && ({ ...item.media[0] } as IMedia);
    }, [item?.media]);

    const ctaOfItem = useMemo(() => {
        const layoutFromPage = contentPage?.layouts.find(
            (layoutLocal) => layoutLocal.layoutID === layout.layoutID
        );

        return layoutFromPage?.items[itemIndex]?.cta ?? null;
    }, [contentPage?.layouts, itemIndex, layout.layoutID]);

    // console.log('media', media?.path ?? '');
    // console.log('mediapath sanitized', imagePathSanitizer(media?.path ?? ''));

    const wrappedElement = useMemo(() => {
        return (
            <>
                {!media && (
                    <button
                        className="block h-full w-full bg-neutral-100 transition-all group hover:bg-cloudbarPrimary-light/50"
                        onClick={showEditor}
                    >
                        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center group min-h-12">
                            <PhotoIcon className="h-24 text-neutral-200 transition-all group-hover:text-white" />
                        </div>
                    </button>
                )}
                {item && media && (
                    <Image
                        className="h-full w-full object-cover"
                        src={imagePathSanitizer(media?.path) ?? ''}
                        alt={media?.alternativeText ?? ''}
                        aria-describedby="Detailed and bigger image of the selected item"
                        unoptimized={true}
                        data-test-id="product-details-thumbnail-image"
                        width={media.width}
                        height={media.height}
                        blurDataURL="/assets/blur/1x1-dcdcdc51.png"
                        placeholder="blur"
                        loading="eager"
                        priority
                    />
                )}
                {item && (
                    <div className="absolute bottom-2 left-2 md:bottom-12 md:left-12 flex w-full flex-col gap-2 max-w-[50%]">
                        {index !== undefined && index !== null && !showTextEditor && showText && (
                            <CMSTextItem
                                index={index}
                                itemIndex={itemIndex}
                                styles={ETextItemStyles.invertLight}
                                richTextClasses="CMSImage16by9"
                                offerButtonClickSettings={true}
                                buttons={{
                                    bold: true,
                                    italic: true,
                                    underline: true,
                                    paragraph: true,
                                    bulletedList: false,
                                    numberedList: false,
                                    headingOne: false,
                                    headingTwo: false,
                                }}
                            />
                        )}
                    </div>
                )}
            </>
        );
    }, [index, item, itemIndex, media, showEditor, showText, showTextEditor]);

    const isClickable: boolean = useMemo(() => {
        // At small screen it makes sense to only use the square
        if (
            isSmartphone &&
            (ctaOfItem?.mode === ECMSCTAMode.button || ctaOfItem?.mode === ECMSCTAMode.both)
        ) {
            return true;
        }

        return ctaOfItem?.mode === ECMSCTAMode.blank || ctaOfItem?.mode === ECMSCTAMode.both;
    }, [ctaOfItem?.mode, isSmartphone]);

    return (
        <>
            {(!ctaOfItem || !ctaOfItem.href || !isClickable || cmsControls) && (
                <div className="relative flex w-full flex-col bg-neutral-100 aspect-[1/1]">
                    {wrappedElement}
                </div>
            )}

            {ctaOfItem && ctaOfItem.href && !cmsControls && isClickable && (
                <Link
                    href={ctaOfItem.href}
                    target={ctaOfItem.target ?? undefined}
                    className="relative flex w-full flex-col bg-neutral-100 transition-all duration-200 ease-in aspect-[1/1] hover:shadow hover:scale-[101%] hover:shadow-neutral-500"
                >
                    {wrappedElement}
                </Link>
            )}
        </>
    );
};
