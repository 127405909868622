import React, { useCallback, useMemo } from 'react';
import { useImageCropperState } from '../../../../general/controls/cropper/tests/ImageCropper.state';
import { ImageCropper } from '../../../../general/controls/cropper/ImageCropper';
import { IImageCropperTypes } from '../../../../general/controls/cropper/interfaces/ImageCropper.types';

interface ICMSImageCropperAdapter {}

export const CMSImageCropperAdapter: React.FC<ICMSImageCropperAdapter> = () => {
    const { set, media } = useImageCropperState();

    const handleClose = useCallback(() => {
        set({ media: null });
    }, [set]);

    const handleOnSave = useCallback(
        (cropData: IImageCropperTypes) => {
            // console.log('cropData', cropData);

            void handleClose();

            return cropData;
        },
        [handleClose]
    );

    const aspectRatio = useMemo(() => {
        if (!media?.width) {
            return 1;
        }

        return media?.width * media?.height;
    }, [media?.height, media?.width]);

    return (
        <>
            {media && (
                <ImageCropper
                    media={media}
                    onSave={handleOnSave}
                    closeFunction={handleClose}
                    aspectRatio={aspectRatio}
                    cropSize={{ width: 100, height: 100 }}
                />
            )}
        </>
    );
};
