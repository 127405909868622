import React, { useCallback, useMemo, useState } from 'react';
import Cropper, { Area, Point, Size } from 'react-easy-crop';
import { Button } from '../button/Button';
import { ArrowUturnLeftIcon, ArrowUturnRightIcon } from '@heroicons/react/16/solid';
import { IImageCropperTypes } from './interfaces/ImageCropper.types';
import { ModalBorderless } from '../../modals/ModalBorderless';
import { IMedia } from '../../../../interfaces/cart/productInCart';
import { imagePathSanitizer } from '../../../../helper/imagePathSanitizer';

export interface IImageCropper {
    media?: IMedia;
    cropShape?: 'rect' | 'round';
    cropSize?: Size | undefined;
    showGrid?: boolean | undefined;
    aspectRatio?: number;
    objectFit?: 'contain' | 'cover' | 'horizontal-cover' | 'vertical-cover' | undefined;
    onCropComplete?: ((croppedArea: Area, croppedAreaPixels: Area) => void) | undefined;
    onSave?: (cropData: IImageCropperTypes) => void;
    closeFunction?: () => void;
}

// https://www.npmjs.com/package/react-easy-crop
export const ImageCropper: React.FC<IImageCropper> = (props) => {
    const {
        cropShape,
        cropSize,
        showGrid,
        aspectRatio,
        objectFit,
        onCropComplete,
        onSave,
        media,
        closeFunction,
    } = props;

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const [cropResults, setCropResults] = useState<Area>({
        x: media?.config?.crop?.x ?? 0,
        y: media?.config?.crop?.y ?? 0,
        width: media?.config?.crop?.width ?? 0,
        height: media?.config?.crop?.height ?? 0,
    });

    const [rotation, setRotation] = useState(media?.config?.crop?.rotation ?? 0);

    const handleSave = useCallback(() => {
        if (!onSave) return;

        onSave({
            aspectRatio: aspectRatio ?? 4 / 3,
            zoom,
            x: crop.x,
            y: crop.y,
            rotation,
            width: cropResults?.width,
            height: cropResults?.height,
        });
    }, [
        aspectRatio,
        crop.x,
        crop.y,
        cropResults?.height,
        cropResults?.width,
        onSave,
        rotation,
        zoom,
    ]);

    const pathSanitized = useMemo(() => {
        if (!media?.path) return;

        if (media?.path.startsWith('/content')) {
            return imagePathSanitizer(media?.path);
        }
        return media?.path;
    }, [media?.path]);

    return (
        <ModalBorderless
            show={true}
            afterClose={() => {}}
            closeable={true}
            title="Bild bearbeiten"
            maxWidthPreset={'7xl'}
        >
            <div
                className="relative mx-auto flex items-center justify-center bg-white"
                data-test-id="cropper-wrapper"
                style={{
                    height: `${media?.height}px`,
                    width: `${media?.width}px`,
                    aspectRatio: aspectRatio,
                }}
            >
                {media?.path && (
                    <Cropper
                        style={{
                            containerStyle: {
                                background: 'white',
                            },
                        }}
                        image={pathSanitized}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectRatio ?? 4 / 3}
                        onCropChange={setCrop}
                        onCropComplete={(croppedArea, croppedAreaPixels) => {
                            setCropResults(croppedAreaPixels);
                            if (onCropComplete) onCropComplete(croppedArea, croppedAreaPixels);
                        }}
                        onZoomChange={setZoom}
                        rotation={rotation}
                        cropShape={cropShape ?? 'rect'}
                        cropSize={cropSize ?? { width: 200, height: 200 }}
                        showGrid={showGrid ?? true}
                        objectFit={objectFit ?? 'contain'}
                    />
                )}
            </div>

            {/* Buttons */}
            <section className="relative flex w-full flex-row justify-between gap-1 px-2 py-2">
                <div className="flex gap-2">
                    <Button
                        color="neutral"
                        onClick={() => {
                            if (closeFunction) closeFunction();
                        }}
                        testId="cropper-close"
                    >
                        Close
                    </Button>

                    <Button
                        color="primary"
                        testId="cropper-rotate-left"
                        onClick={() => {
                            setRotation((rotation - 90) % 360);
                        }}
                        iconStart={<ArrowUturnLeftIcon className="h-4" />}
                    >
                        -90°
                    </Button>
                    <Button
                        color="primary"
                        testId="cropper-rotate-right"
                        onClick={() => {
                            setRotation((rotation + 90) % 360);
                        }}
                        iconEnd={<ArrowUturnRightIcon className="h-4" />}
                    >
                        +90°
                    </Button>
                </div>

                <Button color="success" onClick={handleSave} testId="cropper-submit">
                    Save
                </Button>
            </section>
        </ModalBorderless>
    );
};
