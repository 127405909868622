export enum ECMSLayouts {
    'image16by9' = 'image16by9',
    'imageImages1' = 'imageImages1',
    'imageText1' = 'imageText1',
    'textText1' = 'textText1',
    'textImage1' = 'textImage1',
    'text1' = 'text1',
    'imageRow3' = 'imageRow3',
    'imageRow6' = 'imageRow6',
    'imageBanner' = 'imageBanner',
    'imageTextRow' = 'imageTextRow',
    'textImageRow' = 'textImageRow',
}

export const ECMSLayoutsLabels = {
    [ECMSLayouts.image16by9]: 'Bild-16:9',
    [ECMSLayouts.imageImages1]: 'Bild-Bilder-1',
    [ECMSLayouts.imageText1]: 'Bild-Text-1',
    [ECMSLayouts.textImage1]: 'Text-Bild-1',
    [ECMSLayouts.textText1]: 'Text-Text-1',
    [ECMSLayouts.text1]: 'Text-1',
    [ECMSLayouts.imageRow3]: 'Bild-Reihe-3',
    [ECMSLayouts.imageRow6]: 'Bild-Reihe-6',
    [ECMSLayouts.imageBanner]: 'Bild-Banner',
    [ECMSLayouts.imageTextRow]: 'Bild-Text-Reihe',
    [ECMSLayouts.textImageRow]: 'Text-Bild-Reihe',
};
