import { IFieldMapped, IUser, IUserFromDB } from '../../../../../interfaces/IUser';
import { IField } from '../../../../../interfaces/IField';
import { IRole } from '../../../../../interfaces/role/IRole';

export const mapUserFromDBToFe = (user: IUserFromDB): IUser => {
    const mappedFields: IFieldMapped = {};

    user.fields?.forEach((item: IField) => {
        mappedFields[item.name] = item.value ?? '';
    });

    const firstName = mappedFields?.generalFirstName ?? '';
    const lastName = mappedFields?.generalLastName ?? '';
    const fullName = `${firstName} ${lastName}`;

    return {
        id: user?.id,
        name: user?.name,
        archived: user?.archived,

        fullName: fullName,

        userName: user?.name,
        picture: null,
        fields: mappedFields,
        admin: user?.roles?.some((role) => role.admin),

        email: user.email,
        roles: user.roles as unknown as IRole[],
        tenantIDs: user.tenantIDs,
        ariba: user?.ariba ?? null,
    };
};
