import { useSetRecoilState } from 'recoil';
import { currentUserState, loggedInUserSelector } from '../../state/currentUser';
import { useWhoAmIQuery } from './useWhoAmIQuery';
import { useEffect } from 'react';
import { useLocalisationState } from '../../Translations/state/localisation.state';
import { ILocale } from '../../../../interfaces/localisation/locale.interfaces';
import { tenantSelector } from '../../state/tenantState';
import { useRecoilValue } from 'recoil';

export const useUserSelf = () => {
    const setCurrentUserState = useSetRecoilState(currentUserState);
    const setLogginedInUserState = useSetRecoilState(loggedInUserSelector);
    const { updateUserCurrentLanguage } = useLocalisationState();
    const currentTenant = useRecoilValue(tenantSelector);
    const { data: user } = useWhoAmIQuery();

    useEffect(() => {
        if (user) {
            setCurrentUserState((current) => {
                return {
                    ...current,
                    user: user,
                };
            });

            const generalLanguage = user.fields?.generalLanguage;
            if (generalLanguage) {
                // Find if this language is a default language in tenant
                const isDefault = currentTenant?.languages?.some(
                    (lang) => lang.languageCode === generalLanguage && lang.default
                ) || false;

                const currentLanguage: ILocale = {
                    languageCode: generalLanguage,
                    default: isDefault,
                };
                updateUserCurrentLanguage(currentLanguage);
            }

            setLogginedInUserState(user);
        }
    }, [user, setCurrentUserState, setLogginedInUserState, updateUserCurrentLanguage, currentTenant]);

    return { user };
};
