import React from 'react';
import { ITranslations } from '../../../../interfaces/translation/translations.interfaces';
import { useTenantSelf } from '../../hooks/tenant/useTenantSelf';
import { TranslationAutomationDisplayPort } from './TranslationAutomationDisplayPort';
import { useAutomaticTranslation } from '../hooks/useAutomaticTranslation';

interface ITranslationAutomationDisplay {
    translations: ITranslations;
    show?: boolean;
}

export const TranslationAutomationDisplayAdapter: React.FC<ITranslationAutomationDisplay> = (
    props
) => {
    const { translations, show = false } = props;
    const { tenant } = useTenantSelf();

    const { automaticTranslationActive } = useAutomaticTranslation();

    return (
        <>
            {tenant && tenant.languages && show && (
                <TranslationAutomationDisplayPort
                    translations={translations}
                    tenantLanguages={tenant?.languages}
                    aiTranslationActive={automaticTranslationActive}
                />
            )}
        </>
    );
};
