import React, { useCallback, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useSetRecoilState } from 'recoil';
import { ECMSLayouts } from '../../../../admin/areas/pageCMSLayoutEditor/interfaces/CMSLayouts.enum';
import { CMSLayoutThumbnail } from '../../../../admin/areas/pageCMSLayoutEditor/elements/CMSLayoutThumbnail';
import { RippleButton } from '../../../../general/controls/button/RippleButton';
import { useContentLayoutCreateMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutCreateMutation';
import { IContentPage } from '../../../../../interfaces/CMS/cms.interfaces';
import { toast } from 'react-toastify';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';
import { Transition } from '@headlessui/react';
import { EAccess } from '../../../../../interfaces/role/IAccess';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { useAccess } from '../../../../general/access/hooks/useAccess';
import { Button } from '../../../../general/controls/button/Button';

interface IPageCMSInsertNewLayout {
    pageID: string;
    currentIndex: number;
    slug: string;
}

export const PageCMSInsertNewLayout: React.FC<IPageCMSInsertNewLayout> = (props) => {
    const { pageID, currentIndex, slug } = props;

    const { hasAccess } = useAccess();

    const { getT } = useTranslation();

    const setLayouts = useSetRecoilState(adminPageLayoutSelector);

    const { mutateAsync, isPending } = useContentLayoutCreateMutation(slug);

    const [open, setOpen] = useState(false);

    const handleAddLayout = useCallback(
        (type: ECMSLayouts) => {
            if (currentIndex === undefined) return;

            // Insert new Layout
            void mutateAsync({
                type: type,
                pageID,
                orderNo: currentIndex,
            })
                .then((response: IContentPage) => {
                    setOpen(false);
                    // Updates the value, to refresh the data
                    setLayouts(response.layouts as never);
                })
                .catch((reason) => {
                    console.error(reason);
                    toast.error(
                        getT('toastErrorWhenAddingLayout') ?? 'Fehler beim Hinzufügen des Layouts'
                    );
                    setOpen(false);
                });
        },
        [currentIndex, getT, mutateAsync, pageID, setLayouts]
    );

    return (
        <>
            {hasAccess([EAccess.qContent, EAccess.mContent]) && (
                <div className="relative z-20 flex h-1 w-full border-b-2 border-dashed py-4 group border-cloudbarPrimary-light">
                    <div className="relative top-0 mx-auto transition-all">
                        <button
                            className="rounded px-3 py-2 text-sm font-semibold text-white transition-all bg-cloudbar"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            Neues Element einfügen
                        </button>
                    </div>

                    {/* TODO */}
                    {open && (
                        <div className="absolute top-14 z-50 flex h-max w-full flex-col bg-neutral-100 shadow transition-all">
                            <div className="relative flex flex-row flex-wrap items-start justify-start gap-6 p-6">
                                {Object.keys(ECMSLayouts).map((key: string, index) => {
                                    return (
                                        <Transition
                                            key={`ripple-${key}`}
                                            show={true}
                                            appear={true}
                                            className="relative top-0 left-0 flex"
                                            enter="transition transform ease-in-out duration-100 delay-100"
                                            enterFrom="opacity-0 translate-y-20"
                                            enterTo="opacity-100 translate-y-0"
                                            style={{ transitionDelay: `${index * 35}ms` }}
                                        >
                                            <RippleButton
                                                onClick={() => {
                                                    if (isPending) return;

                                                    handleAddLayout(key as ECMSLayouts);
                                                }}
                                                key={`ripple-${key}`}
                                                wrapperCss="w-max hover:scale-110 transition-all"
                                            >
                                                <CMSLayoutThumbnail value={key as ECMSLayouts} />
                                            </RippleButton>
                                        </Transition>
                                    );
                                })}
                            </div>
                            <div className="relative flex flex-row flex-wrap items-end justify-end gap-6 p-6">
                                <Button
                                    color="neutral"
                                    iconStart={<XMarkIcon className="h-5" />}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    Schließen
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
