import { useCallback } from 'react';
import { Descendant } from 'slate';
import _ from 'lodash';
import { IContentLayoutItem, IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { useSetRecoilState } from 'recoil';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';

export const useRichTextSaveToLayoutState = () => {
    const setPageLayout = useSetRecoilState(adminPageLayoutSelector);
    const { userCurrentLanguage } = useLocalisationState();

    const saveToLayoutState = useCallback(
        (value: Descendant[], index: number, basicItemIndex: number) => {
            if (!userCurrentLanguage || !userCurrentLanguage.languageCode) {
                return;
            }

            // Update layout with id in pageLayout, update the items with index and JSON.stringify(value) in default
            setPageLayout((currVal) => {
                if (!currVal) return null;

                const newLayouts: IContentLayout[] = _.cloneDeep(currVal);
                const newLayout = newLayouts[index] ?? undefined;

                // Update
                if (newLayout) {
                    const newItems = [...newLayout.items];
                    const currentItem = newItems[basicItemIndex] as IContentLayoutItem;
                    const jsonValue = JSON.stringify(value);

                    // If this is the default language, update baseTranslation
                    if (userCurrentLanguage.default) {
                        newItems[basicItemIndex] = {
                            ...currentItem,
                            content: {
                                ...currentItem.content,
                                translationKey: currentItem.content?.translationKey || '',
                                languageCode: userCurrentLanguage.languageCode,
                                baseTranslation: jsonValue,
                                // Keep existing translations array
                                translations: currentItem.content?.translations || [],
                            },
                        };
                    } else {
                        // Non-default language, update only the specific translation
                        const existingTranslations = currentItem.content?.translations || [];

                        // Find if the current language already exists in translations
                        const currentLangIndex = existingTranslations.findIndex(
                            (t) => t.languageCode === userCurrentLanguage.languageCode
                        );

                        // Create a new translations array with updated or added current language
                        const updatedTranslations = [...existingTranslations];
                        if (currentLangIndex !== -1) {
                            // Update existing translation for current language
                            updatedTranslations[currentLangIndex] = {
                                languageCode: userCurrentLanguage.languageCode,
                                translation: jsonValue,
                            };
                        } else {
                            // Add new translation for current language
                            updatedTranslations.push({
                                languageCode: userCurrentLanguage.languageCode,
                                translation: jsonValue,
                            });
                        }

                        newItems[basicItemIndex] = {
                            ...currentItem,
                            content: {
                                ...currentItem.content,
                                translationKey: currentItem.content?.translationKey || '',
                                languageCode: userCurrentLanguage.languageCode,
                                // Keep the existing baseTranslation
                                baseTranslation: currentItem.content?.baseTranslation || '{}',
                                translations: updatedTranslations,
                            },
                        };
                    }

                    newLayout.items = newItems;
                    newLayouts[index] = newLayout;
                }

                return newLayouts;
            });
        },
        [setPageLayout, userCurrentLanguage]
    );

    return { saveToLayoutState };
};
