import React, { useMemo } from 'react';
import { useHeadlineSaveToLayoutState } from '../hooks/useHeadlineSaveToLayoutState';
import SlateEditorInPlace from '../../../../general/controls/RichText/SlateEditorInPlace';
import {
    TRichTextDataObject,
    useRichTextDataTypeAnalyzer,
} from '../../../../general/controls/RichText/helper/useRichTextDataTypeAnalyzer';
import { useConvertJSONToSlate } from '../helper/useConvertJSONToSlate';
import { IContentLayoutItem } from '../../../../../interfaces/CMS/cms.interfaces';
import { useUpdateLayoutToAPI } from '../hooks/useUpdateLayoutToAPI';
import { ERichTextStyles } from '../../../../general/controls/RichText/RichTextDisplay';
import { EAccess } from '../../../../../interfaces/role/IAccess';
import { useAccess } from '../../../../general/access/hooks/useAccess';
import { TSlateButtons } from '../../../../general/controls/RichText/elements/SlateButtonRow';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';
import { useAdminControlsState } from '../../../../general/elements/adminControls/state/adminControls.state';
import { TranslationAutomationDisplayAdapter } from '../../../../general/Translations/elements/TranslationAutomationDisplayAdapter';

export interface ICMSElementTitle {
    item: IContentLayoutItem;
    index: number;
    itemIndex: number;
    styles?: ERichTextStyles;
    richTextClasses?: string;
    buttons?: TSlateButtons;
}

export const CMSElementTitle: React.FC<ICMSElementTitle> = (props) => {
    const {
        item,
        itemIndex,
        index,
        styles = ERichTextStyles.cmsTitleStyles,
        richTextClasses = '',
        buttons = {
            bold: false,
            italic: false,
            underline: false,
            bulletedList: false,
            numberedList: false,
            headingOne: true,
            headingTwo: false,
        },
    } = props;

    const { hasAccess } = useAccess();
    const { userCurrentLanguage } = useLocalisationState();

    const { cmsControls } = useAdminControlsState();

    const { saveToLayoutState: saveTitle } = useHeadlineSaveToLayoutState();
    const { saveLayoutItemToAPI } = useUpdateLayoutToAPI();

    const { convertAsRichTextDataType } = useRichTextDataTypeAnalyzer();
    const { converJSONToSlate } = useConvertJSONToSlate();

    const languageBasedTranslation = useMemo(() => {
        if (userCurrentLanguage && userCurrentLanguage?.default) {
            return item?.title?.baseTranslation;
        }

        return item?.title?.translations?.find(
            (t) => t.languageCode === userCurrentLanguage?.languageCode
        )?.translation;
    }, [item?.title?.baseTranslation, item?.title?.translations, userCurrentLanguage]);

    const richTextObject = convertAsRichTextDataType(languageBasedTranslation ?? '', 'slatejs');
    const contentInitial = converJSONToSlate(richTextObject.content);

    // TODO REFACTOR baseTranslationExists.ts
    // Determine if baseTranslation exists and is not empty
    const baseTranslationExists = React.useMemo(() => {
        // Check if baseTranslation exists
        if (!item?.title?.baseTranslation) {
            return false;
        }

        // Quick check for common empty values
        if (
            item.title.baseTranslation === '{}' ||
            item.title.baseTranslation === '' ||
            item.title.baseTranslation === '""'
        ) {
            return false;
        }

        try {
            // Check if it's a slatejs object
            if (item.title.baseTranslation.includes('"type":"slatejs"')) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const slateObj: TRichTextDataObject = JSON.parse(item.title.baseTranslation);

                // Check if it has non-empty content
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (slateObj.type === 'slatejs' && Array.isArray(slateObj.content)) {
                    // Check if it's not just an empty paragraph
                    return !(
                        Array.isArray(slateObj?.content) &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        slateObj?.content?.length === 1 &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        slateObj?.content[0]?.type === 'paragraph' &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        slateObj?.content[0]?.children?.length === 1 &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        slateObj?.content[0]?.children[0]?.text === ''
                    );
                }
            } else {
                // Check for empty object
                if (item.title.baseTranslation === '{}') {
                    return false;
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
                const parsedContent = JSON.parse(item.title.baseTranslation);
                if (
                    typeof parsedContent === 'object' &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    Object.keys(parsedContent).length === 0
                ) {
                    return false;
                }

                // Handle direct slate content - reuse the already parsed content
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const contentObj = parsedContent;

                // Check if it's not just an empty paragraph
                return !(
                    Array.isArray(contentObj) &&
                    contentObj.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    contentObj[0]?.type === 'paragraph' &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    contentObj[0]?.children?.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    contentObj[0]?.children[0]?.text === ''
                );
            }
        } catch (e) {
            // If can't parse JSON, consider it not empty
            return (
                item.title.baseTranslation.length > 0 &&
                item.title.baseTranslation !== '{}' &&
                item.title.baseTranslation !== ''
            );
        }

        return true;
    }, [item?.title?.baseTranslation]);

    const allowEdit = useMemo(() => {
        return hasAccess([EAccess.mContent]) && cmsControls;
    }, [cmsControls, hasAccess]);

    return (
        <div className="relative flex w-full flex-col">
            <SlateEditorInPlace
                testId="cms-element-title"
                initialValue={contentInitial}
                richTextClasses={richTextClasses}
                defaultRenderNode="heading-one"
                allowEdit={allowEdit}
                editAccess={[EAccess.mContent]}
                styles={styles}
                placeholder="Add Title"
                baseTranslationExists={baseTranslationExists}
                sourceLanguage="de-DE" // Default language is German
                targetLanguage={userCurrentLanguage?.languageCode}
                onSave={() => {
                    saveLayoutItemToAPI(index, itemIndex);
                }}
                onChange={(value) => {
                    saveTitle(value, index, itemIndex);
                }}
                buttons={buttons}
                aiTranslationLabel="AI translates Title ..."
            />
            {item.title && (
                <div className="absolute -top-3 right-0">
                    <TranslationAutomationDisplayAdapter
                        translations={item.title}
                        show={allowEdit}
                    />
                </div>
            )}
        </div>
    );
};
