import React from 'react';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSImage } from '../../../elements/CMSMedia/CMSImage';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

export interface ICMSLayoutTextText1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutImage16by9: React.FC<ICMSLayoutTextText1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 1);

    return (
        <div className="relative flex w-full aspect-[16/9]">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            <CMSImage
                layout={layout}
                itemIndex={0}
                index={index}
                imageHint={'Format: 16:9'}
                maxImages={1}
                displayComponent="16by9"
                showCTASettings={true}
                showTextEditor={true}
                showText={true}
                requiredRatio={{
                    width: 16,
                    height: 9,
                }}
            />
        </div>
    );
};
