import React from 'react';
import ExportedImage from 'next-image-export-optimizer';
import { ECMSLayouts } from '../interfaces/CMSLayouts.enum';

export interface ICMSLayoutThumbnail {
    value: ECMSLayouts;
}

export const CMSLayoutThumbnail: React.FC<ICMSLayoutThumbnail> = (props) => {
    const { value } = props;

    const src = `/assets/layouts/${value}.png`;

    return (
        <div className="relative flex">
            <ExportedImage
                src={src}
                alt={`Layout: ${value}`}
                className="w-[150px]"
                width={400}
                height={225}
            />
        </div>
    );
};
