import { Descendant } from 'slate';
import { TRichTextDataObject } from './useRichTextDataTypeAnalyzer';
import { slateConstructIsEmpty } from './slateConstructIsEmpty';

/**
 * Checks if a string contains empty Slate.js content
 * @param content String to check
 * @returns Boolean indicating if the content is empty
 */
export const isEmptySlateContent = (content: string | undefined | null): boolean => {
    if (!content) return true;

    // Quick check for common empty values
    if (content === '{}' || content === '' || content === '""') {
        return true;
    }

    try {
        // Handle specific empty Slate.js construct format
        if (content.includes('"type":"slatejs"')) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const slateObj = JSON.parse(content) as {
                type: string;
                content: Array<{
                    type: string;
                    children: Array<{ text: string; type?: string }>;
                }>;
            };

            // Check if it's a slatejs object with content
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (slateObj.type === 'slatejs' && Array.isArray(slateObj.content)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (slateObj.content.length === 0) return true;

                // Case 1: Check for empty paragraph
                const isEmptyParagraph =
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.type === 'paragraph' &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.children?.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.children[0]?.text === '';

                // Case 2: Check for empty heading-one
                const isEmptyHeadingOne =
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.type === 'heading-one' &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.children?.length === 1 &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    slateObj.content[0]?.children[0]?.text === '';

                return isEmptyParagraph || isEmptyHeadingOne;
            }
        } else {
            // Check for empty object
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
            const parsedContent = JSON.parse(content);

            if (
                typeof parsedContent === 'object' &&
                !Array.isArray(parsedContent) &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                Object.keys(parsedContent).length === 0
            ) {
                return true;
            }

            // Handle direct slate content
            if (Array.isArray(parsedContent)) {
                // Type safety: ensure parsedContent meets the Descendant[] interface requirements
                return slateConstructIsEmpty(parsedContent as Descendant[]);
            }
        }
    } catch (e) {
        // If it's not valid JSON, check if it's empty or just whitespace
        return !content.trim();
    }

    return false;
};

/**
 * Helper to process content and convert empty Slate constructs to empty strings
 * @param content String content to process
 * @returns Empty string if content is an empty Slate construct, otherwise the original content
 */
export const processSlateContent = (content: string | undefined | null): string => {
    if (!content) return '';
    return isEmptySlateContent(content) ? '' : content;
};

/**
 * Checks if a RichTextDataObject contains empty content
 * @param dataObject The RichTextDataObject to check
 * @returns Boolean indicating if the content is empty
 */
export const isEmptyRichTextContent = (dataObject: TRichTextDataObject | undefined | null): boolean => {
    // Check if the dataObject exists
    if (!dataObject || !dataObject.content) {
        return true;
    }

    // If it's an empty string, it's empty
    if (
        dataObject.content === 'null' ||
        dataObject.content === '' ||
        dataObject.content === '{}' ||
        dataObject.content === '""'
    ) {
        return true;
    }

    // Handle based on the type of rich text data
    if (dataObject.type === 'slatejs') {
        try {
            // Try to parse the content as JSON
            const parsedContent = JSON.parse(dataObject.content) as Descendant[];

            // Use the existing slateConstructIsEmpty utility to check
            return slateConstructIsEmpty(parsedContent);
        } catch (e) {
            // If we can't parse it as JSON, but it has content, assume it's not empty
            return !dataObject.content.trim();
        }
    } else if (dataObject.type === 'html') {
        // For HTML type, check if it's just whitespace
        return !dataObject.content.trim();
    }

    // Default case - if we don't know how to check, assume it's not empty if it has content
    return !dataObject.content.trim();
};