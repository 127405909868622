import React, { useMemo } from 'react';
import SlateEditorInPlace from '../../../../general/controls/RichText/SlateEditorInPlace';
import { useUpdateLayoutToAPI } from '../hooks/useUpdateLayoutToAPI';
import { useRichTextDataTypeAnalyzer } from '../../../../general/controls/RichText/helper/useRichTextDataTypeAnalyzer';
import { useConvertJSONToSlate } from '../helper/useConvertJSONToSlate';
import { IContentLayoutItem } from '../../../../../interfaces/CMS/cms.interfaces';
import { useRichTextSaveToLayoutState } from '../hooks/useRichTextSaveToLayoutState';
import { EAccess } from '../../../../../interfaces/role/IAccess';
import { useAccess } from '../../../../general/access/hooks/useAccess';
import { ERichTextStyles } from '../../../../general/controls/RichText/RichTextDisplay';
import { TSlateButtons } from '../../../../general/controls/RichText/elements/SlateButtonRow';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';
import { baseTranslationExists } from '../../../../general/controls/RichText/helper/baseTranslationExists';
import { useAdminControlsState } from '../../../../general/elements/adminControls/state/adminControls.state';
import { TranslationAutomationDisplayAdapter } from '../../../../general/Translations/elements/TranslationAutomationDisplayAdapter';

export interface ICMSElementRichText {
    item: IContentLayoutItem;
    index: number;
    itemIndex: number;
    richTextClasses?: string;
    styles?: ERichTextStyles;
    buttons?: TSlateButtons;
}

export const CMSElementRichText: React.FC<ICMSElementRichText> = (props) => {
    const {
        item,
        itemIndex,
        index,
        richTextClasses = '',
        styles = ERichTextStyles.pageStyles,
        buttons = {
            paragraph: true,
            bold: true,
            italic: true,
            underline: true,
            bulletedList: true,
            numberedList: false,
            headingOne: false,
            headingTwo: true,
        },
    } = props;

    const { userCurrentLanguage } = useLocalisationState();

    const { hasAccess } = useAccess();

    const { saveToLayoutState: saveContent } = useRichTextSaveToLayoutState();
    const { saveLayoutItemToAPI } = useUpdateLayoutToAPI();

    const { cmsControls } = useAdminControlsState();

    const { convertAsRichTextDataType } = useRichTextDataTypeAnalyzer();
    const { converJSONToSlate } = useConvertJSONToSlate();

    const languageBasedTranslation = useMemo(() => {
        if (userCurrentLanguage && userCurrentLanguage?.default) {
            return item?.content?.baseTranslation;
        }

        return item?.content?.translations?.find(
            (t) => t.languageCode === userCurrentLanguage?.languageCode
        )?.translation;
    }, [item?.content?.baseTranslation, item?.content?.translations, userCurrentLanguage]);

    // Determine if baseTranslation exists and is not empty
    const hasBaseTranslation = useMemo(() => {
        return item?.content?.baseTranslation
            ? baseTranslationExists(item.content.baseTranslation)
            : false;
    }, [item?.content?.baseTranslation]);

    // Use regular method calls instead of memoization to ensure fresh content with each language change
    const richTextObject = convertAsRichTextDataType(languageBasedTranslation ?? '', 'slatejs');
    const contentInitial = converJSONToSlate(richTextObject.content);

    const allowEdit = useMemo(() => {
        return hasAccess([EAccess.mContent]) && cmsControls;
    }, [cmsControls, hasAccess]);

    return (
        <>
            {item.content && (
                <div className="relative flex w-full flex-col">
                    <SlateEditorInPlace
                        initialValue={contentInitial}
                        allowEdit={allowEdit}
                        editAccess={[EAccess.mContent]}
                        defaultRenderNode="paragraph"
                        richTextClasses={richTextClasses}
                        styles={styles}
                        baseTranslationExists={hasBaseTranslation}
                        sourceLanguage="de-DE" // Default language is German
                        targetLanguage={userCurrentLanguage?.languageCode}
                        onSave={() => {
                            saveLayoutItemToAPI(index, itemIndex);
                        }}
                        onChange={(value) => {
                            saveContent(value, index, itemIndex);
                        }}
                        buttons={buttons}
                        aiTranslationLabel="AI translates Content ..."
                    />
                    <div className="absolute right-0 -bottom-5">
                        <TranslationAutomationDisplayAdapter
                            translations={item?.content}
                            show={allowEdit}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
