import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IContentLayoutItem } from '../../../../../interfaces/CMS/cms.interfaces';
import { useUpdateLayoutToAPI } from '../hooks/useUpdateLayoutToAPI';
import { TextField } from '../../../../general/controls/texfield/TextField';
import { Button } from '../../../../general/controls/button/Button';
import { EditorModal } from '../../../../general/modals/EditorModal';
import { PlusIcon } from '@heroicons/react/16/solid';
import { TCMSCTA, useCTASaveToLayoutState } from '../hooks/useCTASaveToLayoutState';
import { Infobox } from '../../../../general/display/infobox/Infobox';
import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { toast } from 'react-toastify';
import { EAccess } from '../../../../../interfaces/role/IAccess';
import { useAdminControlsState } from '../../../../general/elements/adminControls/state/adminControls.state';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { useCMSValidationUrl } from '../hooks/useCMSValidationUrl';
import { useAccess } from '../../../../general/access/hooks/useAccess';
import { Cog6ToothIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { Select } from '../../../../general/controls/select/Select';
import { ECMSCTAMode, ECMSCTAModeLabels } from '../enums/ECMSCTAMode.enum';
import { FlagSymbol } from '../../../../general/elements/FlagSymbol/FlagSymbol';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';
import { ITranslation } from '../../../../../interfaces/translation/translations.interfaces';
import { Chip } from '../../../../general/elements/Chips/Chip';

export interface ICMSElementCTA {
    item: IContentLayoutItem;
    index: number;
    itemIndex: number;
    offerButtonClickSettings?: boolean;
}

export const CMSElementCTA: React.FC<ICMSElementCTA> = (props) => {
    const { item, itemIndex, index, offerButtonClickSettings = false } = props;
    const [open, setOpen] = useState(false);

    const { hasAccess } = useAccess();

    const [urlIsValid, setUrlIsValid] = useState<null | boolean>(null);
    const [showLoadingIcon] = useState(false); // For AI translation indicator

    const { userLanguageOptions, tenantDefaultLanguage } = useLocalisationState();

    const [formData, setFormData] = useState<TCMSCTA>({
        href: item?.cta?.href ?? '',
        label: item?.cta?.label ?? {
            translationKey: '',
            languageCode: tenantDefaultLanguage?.languageCode || 'de-DE',
            baseTranslation: '',
            translations: [],
        },
        target: item?.cta?.target ?? '',
        mode: item?.cta?.mode ?? ECMSCTAMode.button,
    });

    const { getT } = useTranslation();

    const { cmsControls } = useAdminControlsState();

    // Function to get the correct translated button label based on current language
    const getTranslatedButtonLabel = useCallback(() => {
        if (!item?.cta?.label) {
            return 'Button';
        }

        // Get current active language
        const currentLanguage = useLocalisationState.getState().userCurrentLanguage?.languageCode;

        if (!currentLanguage) {
            return item.cta.label.baseTranslation || 'Button';
        }

        // If current language matches the base language, use baseTranslation
        if (currentLanguage === item.cta.label.languageCode) {
            return item.cta.label.baseTranslation || 'Button';
        }

        // Otherwise look for a translation matching the current language
        const translation = item.cta.label.translations?.find(
            t => t.languageCode === currentLanguage
        );

        if (translation && translation.translation) {
            return translation.translation;
        }

        // Fallback to base translation if no matching translation found
        return item.cta.label.baseTranslation || 'Button';
    }, [item?.cta?.label]);

    const { saveToLayoutState: saveCTA } = useCTASaveToLayoutState();
    const { saveLayoutItemToAPI } = useUpdateLayoutToAPI();
    const { applyStyles } = useThemeStyles();
    const { sanitizeAndFilterUrl } = useCMSValidationUrl();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleUpdateData = useCallback(() => {
        saveCTA(formData, index, itemIndex);
    }, [formData, index, itemIndex, saveCTA]);

    const handleSaveToAPI = useCallback(() => {
        if (!urlIsValid) {
            toast.error(
                getT('toastErrorURLNotValid') ??
                    'URL ist nicht valide und muss mit https:// beginnen',
                {
                    toastId: 'cta-url-not-valid',
                    autoClose: 4000,
                }
            );
            return; // Don't save if URL is invalid
        }

        // Ensure the translations data structure is correctly formatted
        setFormData((currVal) => {
            // Make sure we have the proper language code set
            const defaultLanguage = tenantDefaultLanguage?.languageCode || 'de-DE';

            // Ensure we have translations array
            const translations = Array.isArray(currVal.label.translations)
                ? currVal.label.translations
                : [];

            // Return the updated form data with proper structure
            return {
                ...currVal,
                label: {
                    ...currVal.label,
                    languageCode: defaultLanguage,
                    translations: translations,
                }
            };
        });

        // After updating the form data, save to API
        setTimeout(() => {
            saveLayoutItemToAPI(index, itemIndex, () => {
                setOpen(false);
                toast.success('Button erfolgreich gespeichert mit Übersetzungen', {
                    toastId: 'cta-translations-saved',
                    autoClose: 2000,
                });
            });
        }, 0);
    }, [getT, index, itemIndex, saveLayoutItemToAPI, urlIsValid, tenantDefaultLanguage?.languageCode]);

    useEffect(() => {
        if (formData) {
            handleUpdateData();
        }
    }, [formData, handleUpdateData]);

    const buttonIsSet = useMemo(() => {
        // true if href is not empty and not null explicitly
        return item?.cta?.href !== '' && item?.cta?.href !== null;
    }, [item?.cta?.href]);

    const buttonUrl = useMemo((): string | null => {
        return sanitizeAndFilterUrl(item?.cta?.href ?? '', false);
    }, [item?.cta?.href, sanitizeAndFilterUrl]);

    const isAllowed: boolean = useMemo(() => {
        return hasAccess([EAccess.qContent, EAccess.mContent]);
    }, [hasAccess]);

    const showAdminButton: boolean = useMemo(() => {
        return cmsControls && isAllowed;
    }, [cmsControls, isAllowed]);

    const showButton: boolean = useMemo(() => {
        if (showAdminButton) return false;

        return formData.mode === ECMSCTAMode.button || formData.mode === ECMSCTAMode.both;
    }, [formData.mode, showAdminButton]);

    // Function to render the default language field (baseTranslation)
    const renderDefaultLanguageField = useCallback(() => {
        const defaultLanguage = tenantDefaultLanguage?.languageCode || 'de-DE';

        return (
            <TextField
                value={formData.label.baseTranslation}
                clearable={true}
                onChange={(value) => {
                    setFormData((currVal) => {
                        return {
                            ...currVal,
                            label: {
                                ...currVal.label,
                                baseTranslation: value,
                            },
                        };
                    });
                }}
                label={`Label (${defaultLanguage}) - Standard`}
                iconStart={<FlagSymbol countryCode={defaultLanguage} />}
                required={true}
                placeholder={
                    showLoadingIcon
                        ? (getT('translationEditorQueuePlaceholder') as string) || "Wird übersetzt..."
                        : "Button Text"
                }
                iconEnd={
                    showLoadingIcon ? (
                        <ArrowPathIcon className="h-5 animate-spin" />
                    ) : undefined
                }
            />
        );
    }, [formData.label.baseTranslation, getT, showLoadingIcon, tenantDefaultLanguage?.languageCode]);

    // Function to render translation fields for all languages except the default one
    const renderTranslationFields = useCallback(() => {
        if (!userLanguageOptions || userLanguageOptions.length <= 1) {
            return null;
        }

        // The default language is displayed separately, here we only show non-default languages
        const nonDefaultLanguages = userLanguageOptions.filter(lang => !lang.default);

        return (
            <div className="relative flex w-full flex-col gap-4">
                {nonDefaultLanguages.map((langOption) => {
                    // Find existing translation for this language code
                    const existingTranslation = formData.label.translations?.find(
                        t => t.languageCode === langOption.languageCode
                    );

                    const translationValue = existingTranslation?.translation || '';

                    return (
                        <TextField
                            key={`translation-${langOption.languageCode}`}
                            value={translationValue}
                            onChange={(value) => {
                                setFormData((currVal) => {
                                    // Create a copy of existing translations
                                    const updatedTranslations = [...(currVal.label.translations || [])];

                                    // Find the index of this language if it exists
                                    const existingIndex = updatedTranslations.findIndex(
                                        t => t.languageCode === langOption.languageCode
                                    );

                                    if (existingIndex >= 0) {
                                        // Update existing translation
                                        const updatedTranslation: ITranslation = {
                                            languageCode: updatedTranslations[existingIndex]?.languageCode || langOption.languageCode,
                                            translation: value
                                        };
                                        updatedTranslations[existingIndex] = updatedTranslation;
                                    } else {
                                        // Add new translation
                                        updatedTranslations.push({
                                            languageCode: langOption.languageCode,
                                            translation: value
                                        });
                                    }

                                    return {
                                        ...currVal,
                                        label: {
                                            ...currVal.label,
                                            translations: updatedTranslations
                                        }
                                    };
                                });
                            }}
                            label={`Label (${langOption.languageCode})`}
                            iconStart={<FlagSymbol countryCode={langOption.languageCode} />}
                            placeholder={
                                showLoadingIcon
                                    ? (getT('translationEditorQueuePlaceholder') as string) || "Wird übersetzt..."
                                    : "Button Text"
                            }
                            iconEnd={
                                showLoadingIcon ? (
                                    <ArrowPathIcon className="h-5 animate-spin" />
                                ) : undefined
                            }
                        />
                    );
                })}
            </div>
        );
    }, [formData.label.translations, getT, showLoadingIcon, userLanguageOptions]);

    return (
        <>
            {/* ADMIN ADD BUTTON */}
            {!buttonIsSet && cmsControls && (
                <div>
                    <button
                        onClick={() => {
                            setOpen(true);
                        }}
                        className="flex flex-row gap-2 border-2 border-dashed px-4 py-2 transition-all group border-cloudbarPrimary bg-cloudbarPrimary-light/20 hover:bg-cloudbarPrimary hover:text-white"
                        style={{
                            ...applyStyles({
                                rounded: 'auto',
                            }),
                        }}
                    >
                        <PlusIcon className="h-6 transition-all text-cloudbarPrimary group-hover:text-white" />
                        <span>Button</span>
                    </button>
                </div>
            )}

            {/* CTA Button Regular View */}
            {buttonIsSet && showButton && item?.cta?.href && (
                <div className="relative flex flex-row items-center justify-start gap-2">
                    <Button href={buttonUrl ?? '#'} target={item.cta.target}>
                        {getTranslatedButtonLabel()}
                    </Button>
                </div>
            )}

            {/*  CTA Button CMS Control View */}
            {buttonIsSet && showAdminButton && item?.cta?.href && (
                <div className="relative flex flex-col items-start justify-center gap-2">
                    {cmsControls && isAllowed && (
                        <Button
                            color="cloudbar"
                            onClick={() => setOpen(true)}
                            iconStart={<Cog6ToothIcon className="h-4 text-white" />}
                            isTranslatable={false}
                        >
                            {getTranslatedButtonLabel()}
                        </Button>
                    )}
                    <div className="absolute top-11 left-0">
                        <Chip color="cloudbar" size="small">
                            <span>Link-Typ:</span>{' '}
                            <span className="font-semibold">
                                {ECMSCTAModeLabels[item?.cta?.mode as string]}
                            </span>
                        </Chip>
                    </div>
                </div>
            )}

            <EditorModal
                testId="cms-cta-admin-modal"
                width="narrow"
                show={open}
                closeable={true}
                closeFunction={handleClose}
                headline="Edit Call-To-Action"
                controlBar={[
                    <Button
                        onClick={handleSaveToAPI}
                        key="save-button"
                        color="success"
                        isTranslatable={false}
                    >
                        CTA Speichern
                    </Button>,
                ]}
            >
                <div className="relative flex w-full flex-col gap-8">
                    <Infobox>
                        Ein Button besteht aus einem Label (sichtbarer Text), einem Link (URL) und
                        einem Target (Ziel). Bitte beachten Sie das die URL mit Https beginnen muss.
                    </Infobox>
                    {/* Labels with translations */}
                    <div className="relative flex w-full flex-col gap-4">
                        <h3 className="font-semibold">Button Text Übersetzungen</h3>
                        {/* Default language (baseTranslation) */}
                        {renderDefaultLanguageField()}

                        {/* Other languages */}
                        {renderTranslationFields()}
                    </div>

                    <div className="relative flex flex-col gap-1">
                        {/* HREF / URL*/}
                        <TextField
                            value={formData.href}
                            validation="urlAndRelative"
                            validationMessage="URL oder relativer Pfad."
                            placeholder="https://... oder /..."
                            showValidation={true}
                            clearable={true}
                            reportValidation={(id, isValid) => {
                                setUrlIsValid(isValid);
                            }}
                            onChange={(value) => {
                                setFormData((currVal) => {
                                    return {
                                        ...currVal,
                                        href: value,
                                    };
                                });
                            }}
                            onBlur={(value) => {
                                const filteredValue = sanitizeAndFilterUrl(value);
                                if (!filteredValue) {
                                    setFormData((currVal) => {
                                        return {
                                            ...currVal,
                                            href: '',
                                        };
                                    });
                                }

                                setFormData((currVal) => {
                                    return {
                                        ...currVal,
                                        href: filteredValue ?? '',
                                    };
                                });
                            }}
                            label="Link / URL"
                        />
                        <Infobox translationKey="cmsPageUrlInfo" small={true} />
                    </div>

                    <div className="relative flex flex-col gap-1">
                        {/* TARGET */}
                        <TextField
                            value={formData.target}
                            placeholder="z.B. kampange-1"
                            clearable={true}
                            onChange={(value) => {
                                setFormData((currVal) => {
                                    return {
                                        ...currVal,
                                        target: value,
                                    };
                                });
                            }}
                            label="Target"
                        />
                        <Infobox small={true}>
                            Ziel des Links: Leer lassen für dasselbe Browserfenster. Für einen neuen
                            Tab bitte Bezeichnung eingeben.
                        </Infobox>
                    </div>

                    {offerButtonClickSettings && (
                        <div className="relative flex flex-col gap-1">
                            <Select
                                options={[
                                    {
                                        name: 'Nur Button',
                                        id: ECMSCTAMode.button,
                                    },
                                    {
                                        name: 'Ohne Button - Kachel ist der Link',
                                        id: ECMSCTAMode.blank,
                                    },
                                    {
                                        name: 'Button + Kachel',
                                        id: ECMSCTAMode.both,
                                    },
                                ]}
                                defaultValue={formData.mode ?? ECMSCTAMode.button}
                                onChange={(value) => {
                                    setFormData((currVal) => {
                                        return {
                                            ...currVal,
                                            mode: value as ECMSCTAMode,
                                        };
                                    });
                                }}
                                label="Klickverhalten"
                                labelDescription={
                                    'Hier wird festgelegt, ob die Kachel und/oder der Button angezeigt und anklickbar sein sollen, um das Verhalten der CTA zu bestimmen.'
                                }
                            />
                        </div>
                    )}
                </div>
            </EditorModal>
        </>
    );
};
