import React from 'react';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSImage } from '../../../elements/CMSMedia/CMSImage';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

export interface ICMSLayoutTextText1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutImageBanner: React.FC<ICMSLayoutTextText1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 1);

    return (
        <div className="relative flex w-full">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            <CMSImage
                layout={layout}
                itemIndex={0}
                index={index}
                imageHint={'Format: 1230 x 256 Pixel'}
                maxImages={1}
                displayComponent="banner"
                showCTASettings={true}
                showTextEditor={true}
                requiredRatio={{
                    width: 1230,
                    height: 256,
                }}
            />
        </div>
    );
};
