import React from 'react';
import { ECMSLayouts } from '../../../admin/areas/pageCMSLayoutEditor/interfaces/CMSLayouts.enum';
import { CMSLayoutTextText1 } from './layoutElements/CMSLayoutTextText1';
import { CMSLayoutImage16by9 } from './layoutElements/CMSLayoutImage16by9';
import { CMSLayoutImageText1 } from './layoutElements/CMSLayoutImageText1';
import { CMSLayoutImageImages1 } from './layoutElements/CMSLayoutImageImages1';
import { useRecoilValue } from 'recoil';
import { adminPageLayoutSelector } from './state/pageEditorStateSelector';
import { CMSLayoutText1 } from './layoutElements/CMSLayoutText1';
import { PageCMSInsertNewLayout } from './elements/PageCMSInsertNewLayout';
import { cmsPageDataSelector } from './state/CMSPage.state';
import { CMSLayoutTextImage1 } from './layoutElements/CMSLayoutTextImage1';
import { useAdminControlsState } from '../../../general/elements/adminControls/state/adminControls.state';
import { CMSLayoutImageRow3 } from './layoutElements/CMSLayoutImageRow3';
import { CMSLayoutImageRow6 } from './layoutElements/CMSLayoutImageRow6';
import { CMSLayoutImageBanner } from './layoutElements/CMSLayoutImageBanner';
import { CMSLayoutImageTextRow } from './layoutElements/CMSLayoutImageTextRow';
import { CMSLayoutTextImageRow } from './layoutElements/CMSLayoutTextImageRow';

interface ICMSPageLoader {}

/**
 * CMS Loader for front PAGE CMS
 * @constructor
 */
export const CMSPageLoader: React.FC<ICMSPageLoader> = () => {
    const layouts = useRecoilValue(adminPageLayoutSelector);

    const contentPage = useRecoilValue(cmsPageDataSelector);

    const { cmsControls } = useAdminControlsState();

    return (
        <div className="relative flex w-full flex-col md:gap-y-8 gap-y-3">
            {contentPage?.pageID && cmsControls && contentPage.slug && (
                <div className="my-4">
                    <PageCMSInsertNewLayout
                        pageID={contentPage?.pageID}
                        currentIndex={-1}
                        slug={contentPage.slug}
                    />
                </div>
            )}

            {layouts &&
                layouts.map((layout, index) => {
                    return (
                        <div className="relative" key={`layout-${layout.layoutID}`}>
                            <div className="pageStyles">
                                {layout.type === ECMSLayouts.text1 && (
                                    <CMSLayoutText1 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.textText1 && (
                                    <CMSLayoutTextText1 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.image16by9 && (
                                    <CMSLayoutImage16by9 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.imageText1 && (
                                    <CMSLayoutImageText1 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.textImage1 && (
                                    <CMSLayoutTextImage1 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.imageImages1 && (
                                    <CMSLayoutImageImages1 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.imageRow3 && (
                                    <CMSLayoutImageRow3 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.imageRow6 && (
                                    <CMSLayoutImageRow6 layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.imageBanner && (
                                    <CMSLayoutImageBanner layout={layout} index={index} />
                                )}
                                {layout.type === ECMSLayouts.imageTextRow && (
                                    <CMSLayoutImageTextRow layout={layout} index={index} />
                                )}{' '}
                                {layout.type === ECMSLayouts.textImageRow && (
                                    <CMSLayoutTextImageRow layout={layout} index={index} />
                                )}
                            </div>
                            {contentPage?.pageID && cmsControls && contentPage.slug && (
                                <div className="mt-8">
                                    <PageCMSInsertNewLayout
                                        pageID={contentPage?.pageID}
                                        slug={contentPage.slug}
                                        currentIndex={index}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};
