import React from 'react';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSTextItem } from '../elements/CMSTextItem';
import { CMSImage } from '../../../elements/CMSMedia/CMSImage';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

export interface ICMSTextImage1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutTextImage1: React.FC<ICMSTextImage1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 2);

    return (
        <div className="relative grid w-full grid-cols-2 gap-x-12">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            <CMSTextItem index={index} itemIndex={0} richTextClasses="CMSLayoutTextImage1" />

            <div className="relative h-full w-full">
                <CMSImage
                    layout={layout}
                    itemIndex={1}
                    index={index}
                    imageHint={'Format: 1:1'}
                    maxImages={1}
                    displayComponent="1by1"
                    showCTASettings={true}
                    requiredRatio={{
                        width: 1,
                        height: 1,
                    }}
                />
            </div>
        </div>
    );
};
