import {
    useContentLayoutItemUpdateMutation,
    CLIUInput,
} from '../../../../general/hooks/pageCMSLayouts/useContentLayoutItemUpdate';
import { useCallback } from 'react';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';
import { processSlateContent } from '../../../../general/controls/RichText/helper/slateHelpers';
import { useTranslationGetContent } from '../../../../general/Translations/hooks/useTranslationGetContent';
import { ITranslations } from 'src/interfaces/translation/translations.interfaces';

export const useUpdateLayoutToAPI = () => {
    const { mutateAsync, isPending } = useContentLayoutItemUpdateMutation();
    const { getT } = useTranslation();
    const layouts = useRecoilValue(adminPageLayoutSelector);
    const { userCurrentLanguage, tenantDefaultLanguage } = useLocalisationState();
    const { getTranslationContent } = useTranslationGetContent();

    // We're now using the utility functions imported from slateHelpers.ts

    const saveLayoutItemToAPI = useCallback(
        (index: number, itemIndex: number, callback?: () => void) => {
            // Find current Item
            const currentLayoutItem = layouts?.[index]?.items[itemIndex];

            if (!currentLayoutItem || !userCurrentLanguage) {
                // eslint-disable-next-line no-console
                console.log('SILENT RETURN #1 currentLayoutItem|userCurrentLanguage');
                return;
            }

            if (!tenantDefaultLanguage || !tenantDefaultLanguage.languageCode) {
                // eslint-disable-next-line no-console
                console.log('SILENT RETURN #2 tenantDefaultLanguage.languageCode');
                return;
            }

            // Get all translatable content using the helper function
            let contentToSave = getTranslationContent(currentLayoutItem?.content, '{}');
            let titleToSave = getTranslationContent(currentLayoutItem?.title, '') || '';
            const subLineToSave = getTranslationContent(currentLayoutItem?.subLine, '') || '';
            const labelToSave = getTranslationContent(currentLayoutItem?.cta?.label, '') || '';

            // Process content and title to check if they're empty Slate constructs
            contentToSave = processSlateContent(contentToSave);
            titleToSave = processSlateContent(titleToSave);

            // Helper function to prepare translation object
            const prepareTranslation = (
                value: string | undefined,
                existingBaseTranslation: string,
                sourceField: ITranslations | undefined,
                isConditional = false
            ) => {
                if (isConditional && !value) return undefined;

                if (userCurrentLanguage.default) {
                    // For default language, update baseTranslation but PRESERVE existing translations
                    return {
                        baseTranslation: value || '',
                        languageCode: tenantDefaultLanguage?.languageCode,
                        translations: sourceField?.translations || [], // Keep existing translations
                    };
                } else {
                    // For non-default language, add/update the translation for current language
                    // First get existing translations excluding the current language
                    const existingTranslations =
                        sourceField?.translations?.filter(
                            (t) => t.languageCode !== userCurrentLanguage.languageCode
                        ) || [];

                    // Then add the current language translation
                    return {
                        baseTranslation: existingBaseTranslation, // Preserve base translation
                        languageCode: tenantDefaultLanguage?.languageCode,
                        translations: [
                            ...existingTranslations, // Keep other translations
                            {
                                languageCode: userCurrentLanguage.languageCode,
                                translation: value || '',
                            },
                        ],
                    };
                }
            };

            // Prepare all translations
            const existingBaseTranslation = currentLayoutItem?.content?.baseTranslation ?? '{}';
            const existingTitleBaseTranslation = currentLayoutItem?.title?.baseTranslation ?? '';
            const existingSubLineBaseTranslation =
                currentLayoutItem?.subLine?.baseTranslation ?? '';
            const existingLabelBaseTranslation =
                currentLayoutItem?.cta?.label?.baseTranslation ?? '';

            const contentTranslation = prepareTranslation(
                contentToSave,
                existingBaseTranslation,
                currentLayoutItem?.content
            );
            const titleTranslation = prepareTranslation(
                titleToSave,
                existingTitleBaseTranslation,
                currentLayoutItem?.title,
                true
            );
            const sublineTranslation = prepareTranslation(
                subLineToSave,
                existingSubLineBaseTranslation,
                currentLayoutItem?.subLine
            );
            const ctaLabelTranslation = prepareTranslation(
                labelToSave,
                existingLabelBaseTranslation,
                currentLayoutItem?.cta?.label,
                true
            );

            // Prepare the API input based on whether it's default language or not
            const apiInput: CLIUInput = {
                type: currentLayoutItem?.type ?? 'contentItem',
                itemID: currentLayoutItem?.itemID ?? '',
                href: currentLayoutItem?.cta?.href ?? undefined,
                target: currentLayoutItem?.cta?.target ?? undefined,
                mode: currentLayoutItem?.cta?.mode ?? undefined,
            };

            // For default language, update direct fields in CLIUInput
            if (userCurrentLanguage.default) {
                apiInput.content = contentToSave;
                if (titleToSave) apiInput.title = titleToSave;
                if (subLineToSave) apiInput.subLine = subLineToSave;
                if (labelToSave) apiInput.label = labelToSave;
            }

            // Always include translation information
            apiInput.contentTranslation = contentTranslation;

            // Always include titleTranslation even if empty, to ensure the title field is not lost
            // If the titleTranslation is undefined, create default title translation while preserving existing translations
            apiInput.titleTranslation = titleTranslation || {
                baseTranslation: userCurrentLanguage.default
                    ? ''
                    : currentLayoutItem?.title?.baseTranslation || '',
                languageCode: tenantDefaultLanguage?.languageCode,
                translations: userCurrentLanguage.default
                    ? currentLayoutItem?.title?.translations || [] // Preserve existing translations in default language
                    : [
                          ...(currentLayoutItem?.title?.translations?.filter(
                              (t) => t.languageCode !== userCurrentLanguage.languageCode
                          ) || []), // Keep other translations
                          {
                              languageCode: userCurrentLanguage.languageCode,
                              translation: '',
                          },
                      ],
            };

            apiInput.sublineTranslation = sublineTranslation; // Using lowercase 'l' as required by GraphQL API
            if (ctaLabelTranslation) apiInput.ctaLabelTranslation = ctaLabelTranslation;

            // Call the mutation with the prepared input
            void mutateAsync(apiInput)
                .then(() => {
                    toast.success(getT('toastSuccessSavedContents') ?? 'Inhalt gespeichert', {
                        autoClose: 500,
                        toastId: 'content-saved-success',
                    });

                    if (callback) {
                        callback();
                    }
                })
                .catch((reason) => {
                    toast.error(
                        getT('toastErrorWhenSavingContent') ?? 'Fehler beim speichern des Inhalts'
                    );
                    console.error(reason);
                });
        },
        [
            getT,
            layouts,
            mutateAsync,
            userCurrentLanguage,
            tenantDefaultLanguage,
            getTranslationContent,
        ]
    );

    return { saveLayoutItemToAPI, isPending };
};
