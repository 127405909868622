import { atom, DefaultValue, selector } from 'recoil';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';

export interface IPageEditorState {
    layouts: IContentLayout[] | null;
}

export const pageEditorStateRecoil = atom<IPageEditorState>({
    key: `page-editor-state-recoil`,
    default: {
        layouts: null,
    },
});

export const adminPageLayoutSelector = selector<null | IContentLayout[]>({
    key: 'admin-page-layout-show',
    get: ({ get }) => {
        return get(pageEditorStateRecoil).layouts;
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(pageEditorStateRecoil, newValue);
        } else {
            set(pageEditorStateRecoil, {
                layouts: newValue as IContentLayout[],
            });
        }
    },
});
