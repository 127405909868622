import React, { useMemo } from 'react';
import { IContentLayoutItem, IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSTextItem } from '../elements/CMSTextItem';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

export interface ICMSLayoutText1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutText1: React.FC<ICMSLayoutText1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 1);

    const itemText = useMemo(() => {
        return { ...(layout.items[0] as IContentLayoutItem) };
    }, [layout]);

    return (
        <div className="relative flex w-full">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            {itemText && (
                <CMSTextItem index={index} itemIndex={0} richTextClasses="CMSLayoutText1" />
            )}
        </div>
    );
};
