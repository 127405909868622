import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useMutation } from '@tanstack/react-query';
import { IContentPage } from '../../../../interfaces/CMS/cms.interfaces';

interface ContentLayoutOderInput {
    pageID: string;
    layoutIDs: string[];
}

export const useContentLayoutOrderMutation = () => {
    const { postGql } = useApi();

    return useMutation<IContentPage, unknown, ContentLayoutOderInput>({
        mutationFn: async (input) => {
            const { contentLayoutOrder: data } = await postGql<{
                contentLayoutOrder: IContentPage;
            }>(
                'contentLayoutOrder',
                gql`
                    mutation ($input: ContentLayoutOderInput!) {
                        contentLayoutOrder(input: $input) {
                            pageID
                            slug
                            layouts {
                                layoutID
                                type
                                items {
                                    itemID
                                    type
                                    title {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    subLine {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    content {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    media {
                                        height
                                        width
                                        id
                                        path
                                        tag
                                    }
                                    cta {
                                        href
                                        label {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        target
                                    }
                                    productIDs
                                    categoryIDs
                                    randomize
                                }
                            }
                        }
                    }
                `,
                { input }
            );

            return data;
        },
    });
};
