import React, { useMemo } from 'react';
import { IMedia } from '../../../../interfaces/cart/productInCart';
import { IContentLayout } from '../../../../interfaces/CMS/cms.interfaces';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { IconButtonSpecial } from '../../../general/controls/button/IconButtonSpecial';
import { CMSImage16by9 } from '../../areas/CMSPage/elements/CMSImage16by9';
import { CMSImage1by1 } from '../../areas/CMSPage/elements/CMSImage1by1';
import { EAccess } from '../../../../interfaces/role/IAccess';
import { CMSImageCropperAdapter } from './elements/CMSImageCropperAdapter';
import { useEnvironment } from '../../../general/hooks/helper/useEnvironment';
import { useAdminControlsState } from '../../../general/elements/adminControls/state/adminControls.state';
import { useAccess } from '../../../general/access/hooks/useAccess';
import { CMSImageBanner } from '../../areas/CMSPage/elements/CMSImageBanner';
import {
    TCMSImageRequiredRatio,
    useCMSImageUploaderState,
} from '../CMSMediaUploader/state/CMSImageUploader.state';
import { useTenantCurrentQuery } from '../../../general/hooks/tenant/useTenantCurrentQuery';
import { useLocalisationState } from '../../../general/Translations/state/localisation.state';

export interface ICMSImage {
    maxImages?: number | 'unlimited';
    imageHint?: string;
    layout: IContentLayout;
    itemIndex: number;
    displayComponent?: '16by9' | '1by1' | 'banner';
    showCTASettings?: boolean;
    index: number;
    showText?: boolean;
    showTextEditor?: boolean;
    requiredRatio?: TCMSImageRequiredRatio;
}

export const CMSImage: React.FC<ICMSImage> = (props) => {
    const {
        maxImages,
        imageHint,
        layout,
        itemIndex,
        displayComponent,
        showCTASettings,
        index,
        showTextEditor = false,
        showText = false,
        requiredRatio,
    } = props;

    const { hasAccess } = useAccess();
    const { data: tenantCurrent } = useTenantCurrentQuery();
    const { setData } = useCMSImageUploaderState();
    const { userCurrentLanguage } = useLocalisationState();

    const hasContentAccess = useMemo(() => {
        return hasAccess([EAccess.qContent, EAccess.mContent]);
    }, [hasAccess]);

    const { isDevelopment } = useEnvironment();
    const { cmsControls } = useAdminControlsState();

    const media: IMedia[] | undefined = useMemo(() => {
        // Sort by orderNo if available
        const mediaItems = layout?.items[itemIndex]?.media;
        return mediaItems?.slice().sort((a, b) => (a.orderNo || 0) - (b.orderNo || 0));
    }, [itemIndex, layout?.items]);

    // Handle both mediaTranslations and mediaTranslation formats
    const mediaTranslations: IMedia[] | undefined = useMemo(() => {
        // First check for mediaTranslations array format
        const translationItems = layout?.items[itemIndex]?.mediaTranslations;
        if (translationItems?.length) {
            return translationItems.slice().sort((a, b) => (a.orderNo || 0) - (b.orderNo || 0));
        }

        // If not found, check for the mediaTranslation object format
        const translationObjects = layout?.items[itemIndex]?.mediaTranslation;
        if (translationObjects?.length) {
            // Find the translation object for the current language and get its media
            const allTranslationMedia = translationObjects.flatMap((t) => t.media || []);
            return allTranslationMedia.slice().sort((a, b) => (a.orderNo || 0) - (b.orderNo || 0));
        }

        return undefined;
    }, [itemIndex, layout?.items]);

    const allowMoreImages = useMemo(() => {
        if (maxImages === 'unlimited') return true;
        if (!media) return false;

        return media?.length < (maxImages ?? 1);
    }, [maxImages, media]);

    const item = useMemo(() => {
        return layout?.items[itemIndex];
    }, [itemIndex, layout?.items]);

    const defaultLanguage = useMemo(() => {
        const defaultLang = tenantCurrent?.languages.find((lang) => lang.default);
        return defaultLang?.languageCode;
    }, [tenantCurrent?.languages]);

    const handleOpenImageEditor = () => {
        setData({
            show: true,
            layout,
            itemIndex,
            media,
            mediaTranslations,
            mediaTranslation: layout?.items[itemIndex]?.mediaTranslation,
            allowMoreImages,
            imageHint,
            showCTASettings,
            hasContentAccess,
            availableLanguages: tenantCurrent?.languages || [],
            defaultLanguage,
            requiredRatio: requiredRatio,
        });
    };

    // Get the appropriate media for the current language
    const displayMedia = useMemo(() => {
        // Check the mediaTranslation structure (nested format) first - this is the primary format
        const translationObjects = layout?.items[itemIndex]?.mediaTranslation;

        if (translationObjects?.length && userCurrentLanguage?.languageCode) {
            // Find exact match by exact language code - no splitting
            const exactTranslationMatch = translationObjects.find(
                (t) => t.languageCode === userCurrentLanguage?.languageCode
            );

            if (exactTranslationMatch?.media?.length) {
                // Found match in mediaTranslation
                return exactTranslationMatch.media;
            }
        }

        // If no mediaTranslation match, check the mediaTranslations array
        if (mediaTranslations?.length && userCurrentLanguage?.languageCode) {
            const matchingTranslations = mediaTranslations.filter(
                (m) => m.languageCode === userCurrentLanguage?.languageCode
            );

            if (matchingTranslations.length > 0) {
                // Found match in mediaTranslations
                return matchingTranslations;
            }
        }

        // Default to original media when no translation is available
        return media;
    }, [userCurrentLanguage?.languageCode, media, mediaTranslations, layout?.items, itemIndex]);

    return (
        <>
            {item && displayComponent === '16by9' && (
                <CMSImage16by9
                    item={{
                        ...item,
                        // Override the media with our language-specific media
                        media: displayMedia,
                    }}
                    index={index}
                    layout={layout}
                    itemIndex={itemIndex}
                    showCTASettings={showCTASettings}
                    showEditor={handleOpenImageEditor}
                    showText={true}
                    showTextEditor={showTextEditor}
                />
            )}

            {item && displayComponent === '1by1' && (
                <CMSImage1by1
                    item={{
                        ...item,
                        // Override the media with our language-specific media
                        media: displayMedia,
                    }}
                    index={index}
                    itemIndex={itemIndex}
                    layout={layout}
                    showEditor={handleOpenImageEditor}
                    showCTASettings={showCTASettings}
                    showText={showText}
                    showTextEditor={showTextEditor}
                />
            )}

            {item && displayComponent === 'banner' && (
                <CMSImageBanner
                    item={{
                        ...item,
                        // Override the media with our language-specific media
                        media: displayMedia,
                    }}
                    index={index}
                    showText={false}
                    itemIndex={itemIndex}
                    layout={layout}
                    showEditor={handleOpenImageEditor}
                    showCTASettings={showCTASettings}
                    showTextEditor={showTextEditor}
                />
            )}

            {cmsControls && (
                <div className="absolute top-2 left-2 z-10">
                    {/* Edit Button*/}
                    <IconButtonSpecial
                        specialButtonCss="relative"
                        hideAdmin={true}
                        access={[EAccess.qContent, EAccess.mContent]}
                        onClick={handleOpenImageEditor}
                        target="cbadmin"
                        tooltip={<span>Bild bearbeiten</span>}
                        showControl={cmsControls}
                    >
                        <PhotoIcon className="h-6" />
                    </IconButtonSpecial>
                </div>
            )}

            {isDevelopment && <CMSImageCropperAdapter />}
        </>
    );
};
