import React, { useCallback, useMemo } from 'react';
import { PopoverMenu } from '../../../../general/elements/popOverMenu/PopoverMenu';
import { IconButton } from '../../../../general/controls/button/IconButton';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/16/solid';
import { useContentLayoutDeleteMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutDeleteMutation';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';
import { useContentLayoutOrderMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutOrderMutation';
import { cmsPageDataSelector } from '../state/CMSPage.state';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { usePageCMSQuery } from '../../../../general/hooks/pageCMS/usePageCMSQuery';
import { IPopOverMenuItem } from '../../../../general/elements/popOverMenu/interfaces/popoverMenu.interfaces';
import { useAdminControlsState } from '../../../../general/elements/adminControls/state/adminControls.state';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';

export interface ICMSLayoutContextMenu {
    allowMoveUp?: boolean;
    allowMoveDown?: boolean;
    allowDelete?: boolean;
    layout: IContentLayout;
}

export const CMSLayoutContextMenu: React.FC<ICMSLayoutContextMenu> = (props) => {
    const { allowDelete, layout } = props;

    const { cmsControls } = useAdminControlsState();

    const { getT } = useTranslation();

    const setLayouts = useSetRecoilState(adminPageLayoutSelector);

    const contentPage = useRecoilValue(cmsPageDataSelector);

    const queryClient = useQueryClient();

    // Index by layout.layoutId form the contentPage.layouts
    const currentIndex = useMemo(() => {
        return contentPage?.layouts.findIndex((l) => l.layoutID === layout.layoutID);
    }, [contentPage?.layouts, layout]);

    const { mutateAsync: deleteMutation, isPending: isPendingDelete } =
        useContentLayoutDeleteMutation();

    const { mutateAsync: orderMutation, isPending: isPendingOrder } =
        useContentLayoutOrderMutation();

    const { refetch } = usePageCMSQuery({
        slug: contentPage?.slug,
    });

    const refresh = useCallback(() => {
        if (!contentPage?.pageID) return;

        void queryClient.invalidateQueries({
            queryKey: [`contentPage-${contentPage?.slug ?? ''}`],
            stale: true,
        });

        void refetch();
    }, [contentPage?.pageID, contentPage?.slug, queryClient, refetch]);

    const items: IPopOverMenuItem[] = useMemo(() => {
        if (currentIndex == undefined) return [];
        if (!contentPage?.layouts) return [];

        const itemsNew: IPopOverMenuItem[] = [];

        if (currentIndex !== 0) {
            itemsNew.push({
                label: 'Nach oben',
                id: 'move-up',
                icon: <ArrowUpIcon className="h-6" />,
                onClick: () => {
                    if (!layout) return;
                    if (isPendingOrder) return;
                    if (!contentPage?.pageID) return;

                    const layouts = contentPage.layouts;

                    // Move layout.layoutId in layouts up in the array
                    const index = layouts.findIndex((l) => l.layoutID === layout.layoutID);
                    if (index === -1) return;
                    if (index === 0) return;

                    const newLayouts = [...layouts];

                    newLayouts.splice(index, 1);
                    newLayouts.splice(index - 1, 0, layout);

                    void orderMutation({
                        pageID: contentPage?.pageID,
                        layoutIDs: newLayouts.map((l) => l.layoutID),
                    }).then((response) => {
                        setLayouts(response.layouts as never);
                        refresh();
                    });
                },
            });
        }

        if (currentIndex < contentPage?.layouts?.length - 1) {
            itemsNew.push({
                label: 'Nach unten',
                id: 'move-down',
                icon: <ArrowDownIcon className="h-6" />,
                onClick: () => {
                    if (!layout) return;
                    if (isPendingOrder) return;
                    if (!contentPage?.pageID) return;

                    const layouts = contentPage.layouts;

                    // Move layout.layoutId in layouts down in the array
                    const index = layouts.findIndex((l) => l.layoutID === layout.layoutID);
                    if (index === -1) return;
                    if (index === layouts.length - 1) return;

                    const newLayouts = [...layouts];

                    newLayouts.splice(index, 1);
                    newLayouts.splice(index + 1, 0, layout);

                    void orderMutation({
                        pageID: contentPage?.pageID,
                        layoutIDs: newLayouts.map((l) => l.layoutID),
                    }).then((response) => {
                        setLayouts(response.layouts as never);
                        refresh();
                    });
                },
            });
        }

        if (allowDelete) {
            itemsNew.push({
                label: 'Delete',
                id: 'delete',
                onClick: () => {
                    if (!layout) return;
                    if (isPendingDelete) return;

                    if (confirm(getT('confirmAreYouSure') ?? 'Sind Sie sicher?')) {
                        void deleteMutation({ layoutID: layout.layoutID }).then((response) => {
                            setLayouts(response.layouts as never);
                            refresh();
                        });
                    }
                },
                icon: <TrashIcon className="h-6 text-danger" />,
            });
        }

        return itemsNew;
    }, [
        allowDelete,
        contentPage?.layouts,
        contentPage?.pageID,
        currentIndex,
        deleteMutation,
        getT,
        isPendingDelete,
        isPendingOrder,
        layout,
        orderMutation,
        refresh,
        setLayouts,
    ]);

    return (
        <div className="absolute top-0 -right-12">
            {layout && cmsControls && (
                <PopoverMenu items={items} openToSide="left">
                    <IconButton
                        outerCss="pointer-events-none"
                        onClick={() => {}}
                        icon={<EllipsisVerticalIcon className="h-6" />}
                    />
                </PopoverMenu>
            )}
        </div>
    );
};
