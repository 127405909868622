import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useMutation } from '@tanstack/react-query';
import { IContentPage } from '../../../../interfaces/CMS/cms.interfaces';

export const useContentPageCreateMutation = () => {
    const { postGql } = useApi();

    return useMutation<
        IContentPage,
        unknown,
        {
            pageID?: string;
            slug: string;
            title: string;
            subLine?: string;
            tags?: string;
            tenantID: string;
        }
    >({
        mutationFn: async (input) => {
            const { contentPageCreate: data } = await postGql<{ contentPageCreate: IContentPage }>(
                'contentPageCreate',
                gql`
                    mutation ($input: ContentPageCreateInput!) {
                        contentPageCreate(input: $input) {
                            pageID
                            title {
                                translationKey
                                baseTranslation
                                languageCode
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            subLine {
                                translationKey
                                baseTranslation
                                languageCode
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            tags
                            slug
                            published
                            layouts {
                                layoutID
                                type
                                items {
                                    itemID
                                    type
                                    title {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    subLine {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    content {
                                        translationKey
                                        baseTranslation
                                        languageCode
                                        translations {
                                            languageCode
                                            translation
                                        }
                                    }
                                    media {
                                        height
                                        width
                                        id
                                        path
                                        tag
                                    }
                                    cta {
                                        href
                                        label {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        target
                                    }
                                    productIDs
                                    categoryIDs
                                    randomize
                                }
                            }
                        }
                    }
                `,
                { input }
            );

            return data;
        },
    });
};
