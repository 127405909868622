import React, { useMemo } from 'react';
import { CMSElementTitle } from './CMSElementTitle';
import { CMSElementRichText } from './CMSElementRichText';
import { CMSElementCTA } from './CMSElementCTA';
import { useCMSLayout } from '../hooks/useCMSLayout';
import { useAdminControlsState } from '../../../../general/elements/adminControls/state/adminControls.state';
import { ERichTextStyles } from '../../../../general/controls/RichText/RichTextDisplay';
import { TSlateButtons } from '../../../../general/controls/RichText/elements/SlateButtonRow';
import { useWindowDimensions } from '../../../../general/hooks/helper/useWindowDimensions';

export enum ETextItemStyles {
    default = 'default',
    invertLight = 'invertLight',
}

export interface ICMSTextItem {
    transparentHeaders?: boolean;
    itemIndex: number;
    index: number;
    styles?: ETextItemStyles;
    richTextClasses?: string;
    buttons?: TSlateButtons;
    offerButtonClickSettings?: boolean;
}

export const CMSTextItem: React.FC<ICMSTextItem> = (props) => {
    const {
        transparentHeaders,
        itemIndex,
        index,
        styles = ETextItemStyles.default,
        richTextClasses = '',
        buttons,
        offerButtonClickSettings = false,
    } = props;

    const { cmsControls } = useAdminControlsState();
    const { isSmartphone } = useWindowDimensions();

    const { getLayoutByIndex } = useCMSLayout();

    const headerClasses = useMemo((): string => {
        return transparentHeaders ? `text-transparent select-none opacity-0` : 'text-primary';
    }, [transparentHeaders]);

    const item = useMemo(() => {
        return getLayoutByIndex(index, itemIndex);
    }, [getLayoutByIndex, index, itemIndex]);

    // Hide empty content field when Editor isn't shown
    // CLOUDBAR-108 CMS: Reduce Space from invisible textfield
    const showContentField = useMemo(() => {
        if (cmsControls) {
            return true;
        }

        return !cmsControls && item?.content?.baseTranslation !== '{}';
    }, [item?.content?.baseTranslation, cmsControls]);

    const stylesClasses: ERichTextStyles = useMemo(() => {
        switch (styles) {
            case ETextItemStyles.invertLight:
                return ERichTextStyles.cmsTitleInvertedStyles;
            default:
                return ERichTextStyles.cmsTitleStyles;
        }
    }, [styles]);

    return (
        <>
            {item && (
                <div className="relative flex md:min-h-12 w-full flex-col gap-1 md:gap-4 ">
                    <div className={headerClasses}>
                        <CMSElementTitle
                            item={item}
                            itemIndex={itemIndex}
                            index={index}
                            styles={stylesClasses}
                            richTextClasses={richTextClasses}
                            buttons={{
                                bold: false,
                                italic: false,
                                underline: false,
                                bulletedList: false,
                                numberedList: false,
                                headingOne: true,
                                headingTwo: false,
                            }}
                        />
                    </div>

                    {item.content && showContentField && (
                        <div className="relative flex w-full">
                            <CMSElementRichText
                                item={item}
                                itemIndex={itemIndex}
                                index={index}
                                richTextClasses={richTextClasses}
                                styles={stylesClasses}
                                buttons={buttons}
                            />
                        </div>
                    )}

                    {!isSmartphone && (
                        <CMSElementCTA
                            item={item}
                            index={index}
                            itemIndex={itemIndex}
                            offerButtonClickSettings={offerButtonClickSettings}
                        />
                    )}
                </div>
            )}
        </>
    );
};
