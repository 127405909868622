import React from 'react';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSTextItem } from '../elements/CMSTextItem';
import { CMSImage } from '../../../elements/CMSMedia/CMSImage';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

export interface ICMSTextImage1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutImageTextRow: React.FC<ICMSTextImage1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 2);

    return (
        <div className="relative grid w-full grid-rows-[auto,max-content] md:grid-cols-[300px,auto] gap-x-3 md:gap-x-8 mb-4 md:mb-0">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />

            <div className="relative">
                <CMSImage
                    layout={layout}
                    itemIndex={0}
                    index={index}
                    imageHint={'Format: 1:1'}
                    maxImages={1}
                    displayComponent="1by1"
                    showCTASettings={false}
                    showText={false}
                    showTextEditor={false}
                />
            </div>

            <CMSTextItem index={index} itemIndex={1} richTextClasses="CMSLayoutImageTextRow" />
        </div>
    );
};
