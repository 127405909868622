import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import { IContentPage } from '../../../../interfaces/CMS/cms.interfaces';
import { useMemo } from 'react';
import { useContentPageCreateMutation } from './usePageCMSCreateMutation';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { tenantSelector } from '../../state/tenantState';
import { useTranslation } from '../../Translations/hooks/useTranslation';
import { nanoid } from 'nanoid';

export const usePageCMSQuery = (input: {
    pageID?: string | null;
    slug?: string | null;
    tenantID?: string | null;
    createOnNotFound?: boolean;
}) => {
    const { mutateAsync, isPending } = useContentPageCreateMutation();
    const { getT } = useTranslation();
    const tenant = useRecoilValue(tenantSelector);
    const { postGql } = useApi();

    const enabled = useMemo((): boolean => {
        if (input.tenantID === null) {
            return false;
        }
        if (!input.pageID && !input.slug) {
            return false;
        }
        return (
            (input.pageID !== undefined && input.pageID !== '' && input.pageID !== null) ||
            (input.slug !== undefined && input.slug !== '' && input.slug !== null)
        );
    }, [input.pageID, input.slug, input.tenantID]);

    return useQuery<IContentPage>({
        staleTime: 30000,
        retry: 0,
        enabled: enabled,
        queryKey: [`contentPage-${input?.pageID ?? input?.slug ?? '-'}`],
        queryFn: async (): Promise<IContentPage> => {
            try {
                const { contentPage } = await postGql<{ contentPage: IContentPage }>(
                    'contentPage',
                    gql`
                        query contentPage($input: ContentPageGetInput!) {
                            contentPage(input: $input) {
                                pageID
                                title {
                                    translationKey
                                    baseTranslation
                                    languageCode
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                subLine {
                                    translationKey
                                    baseTranslation
                                    languageCode
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                tags
                                slug
                                published
                                layouts {
                                    layoutID
                                    type
                                    items {
                                        itemID
                                        type
                                        title {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        subLine {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        content {
                                            translationKey
                                            baseTranslation
                                            languageCode
                                            translations {
                                                languageCode
                                                translation
                                            }
                                        }
                                        media {
                                            height
                                            width
                                            id
                                            path
                                            tag
                                            languageCode
                                            orderNo
                                        }
                                        mediaTranslation {
                                            languageCode
                                            media {
                                                height
                                                width
                                                id
                                                path
                                                tag
                                                languageCode
                                                orderNo
                                            }
                                        }
                                        cta {
                                            href
                                            label {
                                                translationKey
                                                baseTranslation
                                                languageCode
                                                translations {
                                                    languageCode
                                                    translation
                                                }
                                            }
                                            target
                                            mode
                                        }
                                        productIDs
                                        categoryIDs
                                        randomize
                                    }
                                }
                            }
                        }
                    `,
                    {
                        input: {
                            pageID: input.pageID?.length ? input.pageID : null,
                            slug: input.slug?.length ? input.slug : null,
                            tenantID: input.tenantID ?? undefined,
                        },
                    }
                );
                return contentPage;
            } catch (error) {
                const customError = error as Error;

                if (
                    customError.message.toLowerCase().includes('not found') &&
                    tenant &&
                    !isPending &&
                    input.createOnNotFound === true
                ) {
                    try {
                        return await mutateAsync({
                            title: '',
                            subLine: '',
                            tags: '',
                            slug: input?.slug ?? nanoid(),
                            tenantID: tenant.id,
                        });
                    } catch (e) {
                        console.error(e);
                        toast.error(
                            getT('toastErrorWhenCreatingPage') ?? 'Fehler beim Erstellen der Seite'
                        );
                    }
                }
                throw new Error(customError.message);
            }
        },
    });
};
