import { useCallback } from 'react';
import { urlRegExp } from '../../../../../regex/urlRegExp';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';

export const useCMSValidationUrl = () => {
    const { getT } = useTranslation();

    const fireSecurityToast = useCallback(() => {
        toast.warning(getT('cmsPageUrlInvalid'), {
            toastId: 'cta-url-not-valid',
            autoClose: 2000,
        });
    }, [getT]);

    const sanitizeAndFilterUrl = useCallback(
        (url: string, notify = true): string | null => {
            let filteredValue = url.replace(/ /g, '');

            // Check if URL is a valid URL
            const isUrl = urlRegExp.test(filteredValue);

            if (!isUrl && !filteredValue.startsWith('/')) {
                if (notify) {
                    fireSecurityToast();
                }
                return null;
            }

            // only allow relative paths /... or startwith https://
            const whitelist = ['/', 'https://'];
            const isWhitelisted = whitelist.some((allowed) => filteredValue.startsWith(allowed));

            if (!isWhitelisted) {
                if (notify) {
                    fireSecurityToast();
                }
                return null;
            }

            if (isUrl && !filteredValue.startsWith('/')) {
                // Filter itemId from URL - parse URL and remove it and create URL again
                const urlNew = new URL(filteredValue);
                const searchParams = new URLSearchParams(urlNew.search);
                searchParams.delete('itemId');
                urlNew.search = searchParams.toString();
                filteredValue = urlNew.toString();
            }

            // If it's the current domain, remove the domain and make it a relative path
            if (isUrl) {
                // http://localhost:4311/product/?productId=06f176f4-f7e0-476a-9920-eba83f7eaff6&itemId=461d4ed3-5597-4b21-9b89-2f27cd27c8a7
                const urlNew = new URL(filteredValue);
                const currentUrl = new URL(window.location.href);

                if (urlNew.origin === currentUrl.origin) {
                    filteredValue = urlNew.pathname + urlNew.search;
                }
            }

            return filteredValue;
        },
        [fireSecurityToast]
    );

    return { sanitizeAndFilterUrl };
};
