import { create } from 'zustand';
import { IMedia } from '../../../../../interfaces/cart/productInCart';

interface IImageCropper {
    media: IMedia | null;
    set: (state: Partial<IImageCropper>) => void;
}

export const useImageCropperState = create<IImageCropper>((set) => ({
    media: null,
    set: (state) => {
        set(state);
    },
}));
