import React, { useMemo } from 'react';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { ICMSImageElement } from '../../../elements/CMSMedia/interfaces/CMSImageElement.infertaces';
import { CMSTextItem, ETextItemStyles } from './CMSTextItem';
import { CMSElementCTA } from './CMSElementCTA';
import { imagePathSanitizer } from '../../../../../helper/imagePathSanitizer';
import Image from 'next/image';
import { useWindowDimensions } from '../../../../general/hooks/helper/useWindowDimensions';

export interface ICMSTextItem50p extends ICMSImageElement {}

export const CMSImage16by9: React.FC<ICMSTextItem50p> = (props) => {
    const { item, itemIndex, showEditor, index, showTextEditor = false } = props;

    const { isSmartphone } = useWindowDimensions();

    const media: IMedia | undefined = useMemo(() => {
        return item?.media?.[0] && ({ ...item.media[0] } as IMedia);
    }, [item?.media]);

    return (
        <div className="flex w-full flex-col bg-neutral-100 aspect-[16/9]">
            {!media && (
                <button
                    className="relative block h-full w-full bg-neutral-100"
                    onClick={showEditor}
                >
                    <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center min-h-12">
                        <PhotoIcon className="h-24 text-neutral-200" />
                    </div>
                </button>
            )}
            {item && media && (
                <Image
                    unoptimized={true}
                    className="h-full w-full object-cover"
                    src={imagePathSanitizer(media?.path) ?? ''}
                    alt={media?.alternativeText ?? ''}
                    aria-describedby="Detailed and bigger image of the selected item"
                    data-test-id="product-details-thumbnail-image"
                    width={media.width}
                    height={media.height}
                    blurDataURL="/assets/blur/1x1-dcdcdc51.png"
                    placeholder="blur"
                    loading="eager"
                    priority
                />
            )}
            {item && (
                <div className="absolute bottom-12 left-12 flex w-full flex-col gap-2 max-w-[50%]">
                    {showTextEditor && (
                        <CMSTextItem
                            index={index}
                            itemIndex={itemIndex}
                            styles={ETextItemStyles.invertLight}
                            richTextClasses="CMSImage16by9"
                            buttons={{
                                bold: true,
                                italic: true,
                                underline: true,
                                paragraph: true,
                                bulletedList: false,
                                numberedList: false,
                                headingOne: false,
                                headingTwo: false,
                            }}
                        />
                    )}

                    {index !== undefined && !showTextEditor && !isSmartphone && (
                        <CMSElementCTA item={item} index={index} itemIndex={itemIndex} />
                    )}
                </div>
            )}
        </div>
    );
};
