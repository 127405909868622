import { Descendant } from 'slate';
import {
    TRichTextDataObject,
    useRichTextDataTypeAnalyzer,
} from '../../../../general/controls/RichText/helper/useRichTextDataTypeAnalyzer';
import { useCallback } from 'react';
import { isJSON } from '../../../../../helper/isJSON';

export const useConvertJSONToSlate = () => {
    const { getContentStringForSlate } = useRichTextDataTypeAnalyzer();

    const converJSONToSlate = useCallback(
        (content: string): Descendant[] => {
            let parsedToRichText: TRichTextDataObject | string = '{}';

            if (isJSON(content)) {
                parsedToRichText = JSON.parse(content) as TRichTextDataObject | string;
            } else {
                parsedToRichText = {
                    type: 'slatejs',
                    content: [
                        {
                            type: 'paragraph',
                            children: [
                                {
                                    text: content,
                                },
                            ],
                        },
                    ],
                } as unknown as TRichTextDataObject;
            }

            return getContentStringForSlate(parsedToRichText);
        },
        [getContentStringForSlate]
    );

    return { converJSONToSlate };
};
