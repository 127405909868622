import React from 'react';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { CMSImage } from '../../../elements/CMSMedia/CMSImage';
import { useFixLayoutItemsCount } from '../hooks/useFixLayoutItemsCount';
import { CMSLayoutContextMenu } from '../elements/CMSLayoutContextMenu';

export interface ICMSImageImages1 {
    layout: IContentLayout;
    index: number;
}

export const CMSLayoutImageImages1: React.FC<ICMSImageImages1> = (props) => {
    const { layout, index } = props;

    useFixLayoutItemsCount(layout, 3);

    return (
        <div className="relative grid w-full grid-cols-[6fr,3fr] md:gap-x-8 gap-3">
            <CMSLayoutContextMenu allowDelete={true} layout={layout} />
            <div className="relative h-full w-full">
                <CMSImage
                    layout={layout}
                    itemIndex={0}
                    index={index}
                    imageHint={'Format: 1:1'}
                    maxImages={1}
                    displayComponent="1by1"
                    showCTASettings={true}
                    showText={true}
                    requiredRatio={{
                        width: 1,
                        height: 1,
                    }}
                />
            </div>
            <div className="relative grid w-full grid-rows-2 gap-0">
                <div className="relative h-max w-full">
                    <CMSImage
                        layout={layout}
                        itemIndex={1}
                        index={index}
                        imageHint={'Format: 1:1'}
                        maxImages={1}
                        displayComponent="1by1"
                        showCTASettings={true}
                        showText={true}
                        requiredRatio={{
                            width: 1,
                            height: 1,
                        }}
                    />
                </div>
                <div className="relative h-max w-full">
                    <CMSImage
                        layout={layout}
                        itemIndex={2}
                        index={index}
                        imageHint={'Format: 1:1'}
                        maxImages={1}
                        displayComponent="1by1"
                        showCTASettings={true}
                        showText={true}
                        requiredRatio={{
                            width: 1,
                            height: 1,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
