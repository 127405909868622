import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { useCallback, useEffect, useRef } from 'react';
import { useContentLayoutItemCreateMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutItemCreate';
import { useSetRecoilState } from 'recoil';
import { adminPageLayoutSelector } from '../state/pageEditorStateSelector';

export const useFixLayoutItemsCount = (layout: IContentLayout, expectedCount: number) => {
    const { mutateAsync } = useContentLayoutItemCreateMutation();

    const setLayouts = useSetRecoilState(adminPageLayoutSelector);

    const wasInit = useRef(false);

    const fixLayoutItemsCount = useCallback(async () => {
        if (wasInit.current) return;

        const difference = expectedCount - layout.items.length;

        // Create new items to match the expected count
        for (let i = 0; i < difference; i++) {
            try {
                const response = await mutateAsync({
                    layoutID: layout.layoutID,
                    title: '',
                    subLine: '',
                    content: '{}',
                });
                // Update State
                setLayouts(response.layouts as never);
            } catch (reason) {
                console.error('Error creating new layout item', reason);
            }
        }
    }, [expectedCount, layout.items.length, layout.layoutID, mutateAsync, setLayouts]);

    useEffect(() => {
        if (layout && expectedCount !== undefined) {
            if (layout.items.length < expectedCount) {
                void fixLayoutItemsCount();
                wasInit.current = true;
            }
        }
    }, [expectedCount, fixLayoutItemsCount, layout]);
};
