import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { ETranslationType } from '../../../general/Translations/enum/translations.enums';
import { ETranslationEntity } from '../../../general/Translations/types/translationApply.types';

export interface IHeadlineScene {
    headline?: string;
    subline?: string;
    testId?: string;
    headlineIcon?: JSX.Element;

    // Translation properties
    translationKey?: string;
    translationType?: ETranslationType;
    translationEntity?: ETranslationEntity;

    // Subline translation properties
    sublineTranslationKey?: string;
    sublineTranslationType?: ETranslationType;
    sublineTranslationEntity?: ETranslationEntity;
}

export const HeadlineScene: React.FC<IHeadlineScene> = (props) => {
    const {
        headline,
        subline,
        testId,
        headlineIcon,
        translationKey,
        translationType = ETranslationType.static,
        translationEntity = ETranslationEntity.text,
        sublineTranslationKey,
        sublineTranslationType = ETranslationType.static,
        sublineTranslationEntity = ETranslationEntity.text
    } = props;
    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const sublineString = useMemo(() => {
        if (sublineTranslationKey) {
            return getT(sublineTranslationKey);
        }

        return subline ?? null;
    }, [getT, subline, sublineTranslationKey]);

    return (
        <div
            className="relative flex h-full flex-col justify-end gap-1.5"
            data-test-id={`${testId ?? nanoid()}-headlineWrapper`}
        >
            <div className="relative flex flex-row gap-1">
                <div
                    className="text-2xl font-bold text-primary md:text-3xl"
                    data-test-id={`${testId ?? nanoid()}-headline`}
                    {...(translationKey && applyTranslation({
                        key: translationKey,
                        type: translationType,
                        entity: translationEntity
                    }))}
                    style={{
                        color: tenantTheme.colors.primary.DEFAULT,
                    }}
                >
                    {(translationKey && getT(translationKey)) ?? headline ?? ''}
                </div>
                {headlineIcon && (
                    <div
                        data-test-id={`${testId ?? nanoid()}-icon`}
                        className={'flex items-center text-base text-primary-light'}
                        style={{
                            color: tenantTheme.colors.primary.light,
                        }}
                    >
                        {headlineIcon}
                    </div>
                )}
            </div>
            {sublineString && (
                <div
                    className={'text-sm text-text-light'}
                    style={{ color: tenantTheme.colors.body.light }}
                    data-test-id={`${testId ?? nanoid()}-subline`}
                    {...(sublineTranslationKey && applyTranslation({
                        key: sublineTranslationKey,
                        type: sublineTranslationType,
                        entity: sublineTranslationEntity
                    }))}
                >
                    {(sublineTranslationKey && getT(sublineTranslationKey)) ?? subline ?? ''}
                </div>
            )}
        </div>
    );
};
